import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography } from "@mui/material";
import FieldMapComponent from "../molecules/FieldMapComponent";
import FarmFieldManagement from "../organisms/farmManageCard";
import { useNavigate } from "react-router-dom";

import { getOrganizationFarms } from "../../services/api-ffm-service";
import { getLinkedFields } from "../../services/api-gs-service"; 

import { Farm } from "../../models/farm.interface";

import { useConfig } from "../../context/ConfigContextTest";
import { usePopup } from '../../context/PopupContext';

const FarmManagement: React.FC = () => {
  const [farms, setFarms] = useState<Farm[]>([]);
  const { selectedOrganization } = useConfig();
  const navigate = useNavigate();
  const { setShowMappingPopup } = usePopup();

  const isFarmOverviewPage = true;
  const isCropDataPage = false;


  const fetchFarms = useCallback(async () => {
    if (selectedOrganization?.organizationId) {
      try {
        const farmsData = await getOrganizationFarms(selectedOrganization.organizationId);
        setFarms(farmsData);
      } catch (error) {
        console.error("Error fetching farms:", error);
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    fetchFarms();
  }, [fetchFarms]);

  const checkLinkedFields = useCallback(async () => {
    if (farms.length === 0) return;

    for (const farm of farms) {
      try {
        const fieldsData = await getLinkedFields(farm.farmIdentifier);
        if (fieldsData.length > 0) {
          return; 
        }
      } catch (error) {
        console.error(`Error checking linked fields for farm ${farm.farmIdentifier}:`, error);
      }
    }
    
    navigate("/fields", { state: { isAddingField: true } });
    setShowMappingPopup(true);
  }, [farms, navigate, setShowMappingPopup]);

  useEffect(() => {
    checkLinkedFields();
  }, [checkLinkedFields]);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ p: 2, pb: 0 }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Farm Manager
        </Typography>
      </Grid>
      <Grid container>
        <Grid id="farm-field-manage-wrapper" item xs={2.5}>
          <FarmFieldManagement />
        </Grid>
        <Grid item xs={9.5}>  
          <FieldMapComponent
            isFarmOverviewPage={isFarmOverviewPage}
            isCropDataPage={isCropDataPage}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FarmManagement;