import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Collapse, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserOrganizationComponent from "../molecules/UserOrgSelect";
import { Grass, DataUsage, Support } from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import ContactSupportIcon from "@mui/icons-material/ContactSupport"; 
import BusinessIcon from "@mui/icons-material/Business";
import MenuItem from "../molecules/menuItem";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#F1A81E",
    color: "#00000",
  },
  cursor: "pointer",
}));

const CollapsibleSection = ({ open, children }) => {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {children}
      </List>
    </Collapse>
  );
};

const DrawerHeader = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 1),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  color: "white",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NavigationDrawer = ({
  openDrawer,
  handleDrawerOpen,
  handleDrawerClose,
}) => {
  const [openCollapseMyFarm, setOpenCollapseMyFarm] = React.useState(false);
  const [openCollapseOperations, setOpenCollapseOperations] = React.useState(false);
  const [openCollapseCropAndClimate, setOpenCollapseCropAndClimate] = React.useState(false);
  const [openCollapseAdministration, setOpenCollapseAdministration] = React.useState(false);
  const [openCollapseSupport, setOpenCollapseSupport] = React.useState(false);
  const [openCollapseEnterpriseConnect, setOpenCollapseEnterpriseConnect] = React.useState(false);
  const navigate = useNavigate();


  const handleOpenHeading = (section) => {
    const collapseAll = () => {
      setOpenCollapseMyFarm(false);
      setOpenCollapseOperations(false);
      setOpenCollapseCropAndClimate(false);
      setOpenCollapseAdministration(false);
      setOpenCollapseSupport(false);
      setOpenCollapseEnterpriseConnect(false);
    };

    collapseAll();

    switch (section) {
      case "My Farm":
        setOpenCollapseMyFarm(true);
        break;
      case "Operations":
        setOpenCollapseOperations(true);
        break;
      case "Insights":
        setOpenCollapseCropAndClimate(true);
        break;
      case "Administration":
        setOpenCollapseAdministration(true);
        break;
      case "Support":
        setOpenCollapseSupport(true);
        break;
      case "Enterprise Connect":
        setOpenCollapseEnterpriseConnect(true);
        navigate("/enterprise-connect");
        break;
      default:
        break;
    }
    handleDrawerOpen();
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={openDrawer}
      sx={{
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          backgroundColor: "#3C4F1E",
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          height: "calc(100% - 50px)", 
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <DrawerHeader>
          <UserOrganizationComponent
            open={openDrawer}
          ></UserOrganizationComponent>
        </DrawerHeader>
        <Divider />
        <List sx={{ flex: 1, overflowY: "auto" }}>
          {[
            {
              text: "My Farm",
              icon: <Grass sx={{ color: "white" }} />,
              open: openCollapseMyFarm,
            },
            {
              text: "Operations",
              icon: <SettingsIcon sx={{ color: "white" }} />,
              open: openCollapseOperations,
            },
            {
              text: "Insights",
              icon: <DataUsage sx={{ color: "white" }} />,
              open: openCollapseCropAndClimate,
            },
            {
              text: "Administration",
              icon: <AdminPanelSettingsIcon sx={{ color: "white" }} />,
              open: openCollapseAdministration,
            },
            {
              text: "Enterprise Connect",
              icon: <BusinessIcon sx={{ color: "white" }} />,
              open: openCollapseEnterpriseConnect,
            },
            {
              text: "Support",
              icon: <Support sx={{ color: "white" }} />,
              open: openCollapseSupport,
            },
          ].map(({ text, icon, open }, index) => (
            <div key={text}>
              <StyledListItem
                disablePadding
                key={text}
                onClick={() => handleOpenHeading(text)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: openDrawer ? "initial" : "center",
                    px: 2.5,
                    color: "white",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 0,
                      justifyContent: "center",
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    sx={{ opacity: openDrawer ? 1 : 0, ml: openDrawer ? 2 : 0 }}
                  />
                </ListItemButton>
              </StyledListItem>
              {text === "My Farm" && openDrawer && (
                <CollapsibleSection open={open}>
                  <MenuItem
                    text="Farm Manager"
                    icon={undefined}
                    open={openDrawer}
                    onClick={() => navigate("/")}
                  />
                  <MenuItem
                    text="Activity Manager"
                    icon={undefined}
                    open={openDrawer}
                    onClick={() => navigate("/activity")}
                  />
                </CollapsibleSection>
              )}
              {text === "Operations" && openDrawer && (
                <CollapsibleSection open={open}>
                  <MenuItem
                    text="Notes"
                    icon={undefined}
                    open={openDrawer}
                    onClick={() => navigate("/notes")}
                  />
                  {/* <MenuItem
                    text="Warehouse"
                    icon={undefined}
                    open={openDrawer}
                    onClick={undefined}
                  />
                  <MenuItem
                    text="Finances"
                    icon={undefined}
                    open={openDrawer}
                    onClick={undefined}
                  />
                  <MenuItem
                    text="Alerts"
                    icon={undefined}
                    open={openDrawer}
                    onClick={undefined}
                  /> */}
                </CollapsibleSection>
              )}
              {text === "Insights" && openDrawer && (
                <CollapsibleSection open={open}>
                  <MenuItem
                    text="Climate"
                    icon={undefined}
                    open={openDrawer}
                    onClick={() => navigate("/cropClimate")}
                  />
                  <MenuItem
                    text="Crop"
                    icon={undefined}
                    open={openDrawer}
                    onClick={() => navigate("/cropData")}
                  />
                  {/* <MenuItem
                    text="Sensor"
                    icon={undefined}
                    open={openDrawer}
                    onClick={undefined}
                  /> */}
                </CollapsibleSection>
              )}
              {text === "Administration" && openDrawer && (
                <CollapsibleSection open={open}>
                  <MenuItem
                    text="Reports"
                    icon={undefined}
                    open={openDrawer}
                    onClick={() => navigate("/reports")}
                  />
                  <MenuItem
                    text="Documents"
                    icon={undefined}
                    open={openDrawer}
                    onClick={() => navigate("/documentation")}
                  />
                  <MenuItem
                    text="Organisations"
                    icon={undefined}
                    open={openDrawer}
                    onClick={() => navigate("/organisation")}
                  />
                </CollapsibleSection>
              )}
              {text === "Support" && openDrawer && (
                <CollapsibleSection open={open}>
                  <MenuItem
                    text="Tutorials"
                    icon={undefined}
                    open={openDrawer}
                    onClick={() => navigate("/tutorials")}
                  />
                </CollapsibleSection>
              )}
            </div>
          ))}
        </List>
        <Divider />
        <List>
          <StyledListItem
            disablePadding
            onClick={() => navigate("/contact-us")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: openDrawer ? "initial" : "center",
                px: 2.5,
                color: "white",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: openDrawer ? 3 : "auto",
                  justifyContent: "center",
                  color: "white",
                }}
              >
              <ContactSupportIcon />
              </ListItemIcon>
              <ListItemText
                primary="Contact Us"
                sx={{ opacity: openDrawer ? 1 : 0 }}
              />
            </ListItemButton>
          </StyledListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;