import React, { useRef, useState, useCallback, useEffect } from "react";
import {
  Button,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  List,
  ListItem,
  Box,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  createOrganization,
  getLegalEntities,
  getOrganizations,
  createFarm,
} from "../../services/api-ffm-service";
import { LegalEntity } from "../../models/legalEntity.interface";
import { useFetchData } from "../../hooks/useFetchData";
import WelcomeCard from "../molecules/welcome";
import OnBoardingOrganisationForm from "./onBoardingOrganisationDialog";
import { handleMessage } from "../../utils/Utilities";
import { GoogleLocation } from "../../interfaces/googleLocation.interface";
import { Organization } from "../../models/organization.interface";

const stepHeading = ["Let's Get Started!"];
const stepDescriptions = ["COMPLETE YOUR ORGANISATION DETAILS"];
const stepCaptions = [
  [
    "Complete all mandatory fields",
    "Add your Organisation trading name",
    "If your entity has a VAT number, please enter a valid VAT number",
    "If your entity has a Company Registration number, please enter the valid registration number. If not, please enter your ID number",
    "Add your contact details",
    "Be sure to enter a valid cell phone number for OTP authentication",
    "Add a mandatory farm name",
  ],
];
const stepFooter = ["Click NEXT to complete the onboarding process"];

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.background.paper,
  overflow: 'auto',
  borderRadius: theme.shape.borderRadius * 2,
  textAlign: 'center',
}));

const StepperForm: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [legalEntities, setLegalEntities] = useState<LegalEntity[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const orgFormRef = useRef<{ submitForm: () => void }>(null);
  const [isLoading, setIsLoading] = useState(false);

  useFetchData(getLegalEntities, setLegalEntities);

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      handleMessage(event);
    };

    window.addEventListener("message", onMessage);
    return () => window.removeEventListener("message", onMessage);
  }, []);

  const handleNext = useCallback(() => {
    if (activeStep === 1) {
      orgFormRef.current?.submitForm();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [activeStep]);

  const handleOrgSubmit = async (formData: any) => {
    try {
      setIsLoading(true);
      const location = formData.selectedLocation as GoogleLocation;

      const addressLine1 = location ? location.address : "";
      const addressLine2 = location
        ? `${location.coordinates.lat}, ${location.coordinates.lng}`
        : formData.physicalAddressLine2 || "";
      const city = location
        ? location.components.city
        : formData.physicalCity || "";
      const postalCode = location
        ? location.components.postalCode
        : formData.physicalCode || "";

      const transformedData: any = {
        Name: formData.orgName || "",
        VATNumber: formData.vatNumber || "",
        LegalEntityTypeId: formData.legalEntityTypeId || "",
        RegistrationNumber: formData.registrationNumber || "",
        ContactDetail: [
          {
            Name: formData.contactName || "",
            Surname: formData.contactSurname || "",
            Contacts: [
              { Type: "Email", Details: formData.contactEmail || "" },
              { Type: "Mobile", Details: formData.contactNumber || "" },
            ],
          },
        ],
      };

      if (addressLine1) {
        transformedData.PhysicalAddress = {
          AddressLine1: addressLine1,
          AddressLine2: addressLine2,
          City: city,
          Code: postalCode,
        };
      }

      await createOrganization(transformedData);

      const updatedOrganizations = await getOrganizations();
      setOrganizations(updatedOrganizations);
      const createData = {
        name: formData.farmName,
        partyId: updatedOrganizations[0]?.partyId,
      };
      await createFarm(createData);
      window.location.reload();
    } catch (error) {
      console.error("Failed to create organisation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Container
            maxWidth="sm"
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <WelcomeCard />
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 3 }} // Adjust this value to control the space between WelcomeCard and the button
            >
              Next
            </Button>
          </Container>
        );
      case 1:
        return (
          <>
            <OnBoardingOrganisationForm
              ref={orgFormRef}
              onSubmit={handleOrgSubmit}
              legalEntities={legalEntities}
            />
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={isLoading}
              >
                Next
                {isLoading && (
                  <CircularProgress color="inherit" size={20} sx={{ ml: 1 }} />
                )}
              </Button>
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      <Grid container sx={{ height: '100%' }}>
        {activeStep > 0 && (
          <Grid item xs={12} md={3} sx={{ height: '100%', p: 2 }}>
            <StyledPaper elevation={3}>
              <Typography variant="h5" gutterBottom>
                {stepHeading[activeStep - 1]}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {stepDescriptions[activeStep - 1]}
              </Typography>
              <Box sx={{ width: '100%', my: 2 }}>
                <List sx={{ listStyleType: "disc", pl: 4 }}>
                  {stepCaptions[activeStep - 1].map((caption, index) => (
                    <ListItem key={index} sx={{ display: "list-item", pl: 0, textAlign: 'left' }}>
                      <Typography variant="body2">{caption}</Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Typography variant="body2">
                {stepFooter[activeStep - 1]}
              </Typography>
            </StyledPaper>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={activeStep > 0 ? 9 : 12}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 3,
            overflow: 'auto',
          }}
        >
          {renderStepContent()}
        </Grid>
      </Grid>
    </Box>
  );
};

export default StepperForm;