import React, { useState, useEffect, useRef } from 'react';
import { 
  Tabs, 
  Tab, 
  Box, 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  Button, 
  Card, 
  CardContent, 
  Grid, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Checkbox, 
  FormControlLabel, 
  CircularProgress 
} from '@mui/material';

import { styled } from "@mui/material/styles";

import { 
  getActiveEnterprises, 
  initiateEnterpriseRequest, 
  getConnections, 
  acceptEnterpriseConnection, 
  rejectEnterpriseConnection, 
  terminateEnterpriseConnection 
} from '../../services/api-ec-service';

import { 
  EnterpriseDetail, 
  EnterpriseConnection, 
  RejectConnectionRequest 
}  from '../../models/enterpriseConnect.interface';

import { useConfig } from '../../context/ConfigContextTest';

import CustomSnackbar from "../atom/CustomSnackbar";
import GenericConfirmDialog from "../organisms/genericConfirmDialog";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2),
}));

const EnterpriseConnect = () => {
  const { selectedOrganization } = useConfig();
  const [tabValue, setTabValue] = useState(0);
  const [connections, setConnections] = useState<EnterpriseConnection[]>([]);
  const [enterprises, setEnterprises] = useState<EnterpriseDetail[]>([]);
  const [selectedEnterprises, setSelectedEnterprises] = useState<number[]>([]);
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [connectionToTerminate, setConnectionToTerminate] = useState<EnterpriseConnection | null>(null);

  const snackbarRef = useRef<any>(null);

  const showSnackbar = (title: string, message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    if (snackbarRef.current) {
      snackbarRef.current.handleOpen(title, message, severity);
    }
  };

  useEffect(() => {
    if (selectedOrganization) {
      fetchConnections();
    } else {
      setError('No organization selected');
      showSnackbar('Error', 'No organization selected', 'error');
    }
  }, [selectedOrganization]);

  const fetchConnections = async () => {
    if (!selectedOrganization) {
      setError('No organization selected');
      showSnackbar('Error', 'No organization selected', 'error');
      return;
    }

    setIsLoading(true);
    try {
      const connectionsData = await getConnections(
        selectedOrganization.azureUserId,
        selectedOrganization.partyId
      );
      setConnections(connectionsData);
    } catch (err: any) {
      setError(err.message);
      showSnackbar('Error', 'Failed to fetch connections', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const getAvailableEnterprises = () => {
    const unavailableEnterpriseIds = new Set(
      connections
        .filter(connection => 
          connection.statusCode === 'ACT' || 
          connection.statusCode === 'PEN'
        )
        .map(connection => connection.ffePartyId)
    );

    return enterprises.filter(enterprise => 
      !unavailableEnterpriseIds.has(enterprise.partyId)
    );
  };


  const handleEnterpriseSelection = (partyId: number) => {
    setSelectedEnterprises(prev => 
      prev.includes(partyId)
        ? prev.filter(id => id !== partyId)
        : [...prev, partyId]
    );
  };

  const handleSelectAll = () => {
    const availableEnterprises = getAvailableEnterprises();
    if (selectedEnterprises.length === availableEnterprises.length) {
      setSelectedEnterprises([]);
    } else {
      setSelectedEnterprises(availableEnterprises.map(e => e.partyId));
    }
  };

  const handleSendInvites = async () => {
    setIsLoading(true);
    setError(null);

    if (!selectedOrganization) {
        setError('No organization selected');
        showSnackbar('Error', 'No organization selected', 'error');
        setIsLoading(false);
        return;
    }

    try {
        const request = {
            AzureUserId: selectedOrganization.azureUserId,
            FFPPartyId: selectedOrganization.partyId,
            Enterprises: selectedEnterprises
        };

        const response = await initiateEnterpriseRequest(
            request,
            selectedOrganization.azureUserId,
        );

        if (response.statusCode === 200) {
            showSnackbar('Success', 'Invites sent successfully', 'success');
            handleCloseDialog();
            fetchConnections(); 
        } else {
            setError(JSON.stringify(response.errorDetails));
            showSnackbar('Error', 'Failed to send invites', 'error');
        }
    } catch (err: any) {
        setError(err.message);
        showSnackbar('Error', 'An error occurred while sending invites', 'error');
    } finally {
        setIsLoading(false);
    }
  };
  
  const handleAcceptInvite = async (connection: EnterpriseConnection) => {
    if (!selectedOrganization) {
        showSnackbar('Error', 'No organization selected', 'error');
        return;
    }

    setIsLoading(true);
    try {
        const request = {
            EnterpriseFarmConnectId: connection.enterpriseFarmConnectId,
            AzureUserId: selectedOrganization.azureUserId
        };

        const response = await acceptEnterpriseConnection(
            request,
            selectedOrganization.azureUserId,
            selectedOrganization.partyId
        );

        if (response.statusCode === 200) {
            showSnackbar('Success', 'Connection accepted successfully', 'success');
            fetchConnections();
        } else {
            throw new Error(JSON.stringify(response.errorDetails));
        }
    } catch (err: any) {
        setError(err.message);
        showSnackbar('Error', 'Failed to accept connection', 'error');
    } finally {
        setIsLoading(false);
    }
  };

  const handleRejectInvite = async (connection: EnterpriseConnection) => {
    if (!selectedOrganization) {
        showSnackbar('Error', 'No organization selected', 'error');
        return;
    }

    setIsLoading(true);
    try {
        const request: RejectConnectionRequest = {
            EnterpriseFarmConnectId: connection.enterpriseFarmConnectId,
            AzureUserId: selectedOrganization.azureUserId
        };

        const response = await rejectEnterpriseConnection(request);

        if (response.statusCode === 200) {
            showSnackbar('Success', 'Connection rejected successfully', 'success');
            await fetchConnections(); 
        } else {
            throw new Error(JSON.stringify(response.errorDetails));
        }
    } catch (err: any) {
        const errorMessage = err.message || 'Failed to reject connection';
        setError(errorMessage);
        showSnackbar('Error', errorMessage, 'error');
    } finally {
        setIsLoading(false);
    }
  };

  const handleTerminateConnection = async () => {
    if (!selectedOrganization || !connectionToTerminate) {
      showSnackbar('Error', 'Missing required information', 'error');
      return;
    }

    setIsLoading(true);
    try {
      const request = {
        EnterpriseFarmConnectId: connectionToTerminate.enterpriseFarmConnectId,
        AzureUserId: selectedOrganization.azureUserId
      };

      const response = await terminateEnterpriseConnection(request);

      if (response.statusCode === 200) {
        showSnackbar('Success', 'Connection terminated successfully', 'success');
        await fetchConnections();
      } else {
        throw new Error(response.message || 'Failed to terminate connection');
      }
    } catch (err: any) {
      const errorMessage = err.message || 'Failed to terminate connection';
      setError(errorMessage);
      showSnackbar('Error', errorMessage, 'error');
    } finally {
      setIsLoading(false);
      setConfirmDialogOpen(false);
      setConnectionToTerminate(null);
    }
  };

  const handleOpenTerminateDialog = (connection: EnterpriseConnection) => {
    setConnectionToTerminate(connection);
    setConfirmDialogOpen(true);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleOpenDialog = async () => {
    setIsDialogOpen(true);
    setIsLoading(true);
    setError(null);
    setSelectedEnterprises([]);

    try {
      const activeEnterprises = await getActiveEnterprises();
      setEnterprises(activeEnterprises);
    } catch (err) {
      setError('Failed to fetch active enterprises. Please try again.');
      console.error(err);
      showSnackbar('Error', 'Failed to fetch active enterprises', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const renderInvites = () => (
    <List>
      {connections
        .filter(conn => conn.statusCode === 'PEN')
        .map((invite) => (
          <ListItem key={invite.enterpriseFarmConnectId} divider>
            <ListItemText
              primary={invite.enterpriseName}
              secondary={`Status: ${invite.status} | Organization: ${invite.farmerOrganization}`}
            />
            {invite.canAccept && (
              <Button 
                variant="contained" 
                color="primary" 
                style={{ marginRight: 8 }}
                onClick={() => handleAcceptInvite(invite)}
                disabled={isLoading}
              >
                Accept
              </Button>
            )}
            {invite.canReject && (
              <Button 
                variant="outlined" 
                color="error"
                onClick={() => handleRejectInvite(invite)}
                disabled={isLoading}
              >
                Reject
              </Button>
            )}
          </ListItem>
        ))}
    </List>
  );
  
  return (
    <>
      <CustomSnackbar ref={snackbarRef} />

      <Grid container sx={{ p: 2, pb: 0 }}>
        <Grid item xs={2.5}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Enterprise Connect
          </Typography>
        </Grid>   
        <Grid item xs={9.5}>
          {tabValue === 0 && (
            <Button
              variant="contained"
              onClick={handleOpenDialog}
              color="primary"
              disabled={isLoading}
            >
              Add Connection
            </Button>
          )}
        </Grid>
      </Grid>

      <Card>
        <CardContent>
          <StyledTabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Connections" />
            <Tab label="Invites" />
          </StyledTabs>
          <Box>
            {tabValue === 0 && (
              <List>
                {connections
                  .filter(conn => conn.statusCode === 'ACT')
                  .map((connection) => (
                    <ListItem key={connection.enterpriseFarmConnectId}>
                      <ListItemText
                        primary={connection.enterpriseName}
                        secondary={`Status: ${connection.status} | Organization: ${connection.farmerOrganization}`}
                      />
                      {connection.canCancel && (
                        <Button 
                          variant="outlined" 
                          color="error"
                          onClick={() => handleOpenTerminateDialog(connection)}
                          disabled={isLoading}
                        >
                          Terminate
                        </Button>
                      )}
                    </ListItem>
                  ))}
              </List>
            )}
            {tabValue === 1 && renderInvites()}
          </Box>
        </CardContent>
      </Card>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Add Connection</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <>
              {getAvailableEnterprises().length === 0 ? (
                <Typography sx={{ py: 2 }}>
                  No available enterprises to send connections with...
                </Typography>
              ) : (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedEnterprises.length === getAvailableEnterprises().length}
                        indeterminate={selectedEnterprises.length > 0 && 
                                    selectedEnterprises.length < getAvailableEnterprises().length}
                        onChange={handleSelectAll}
                      />
                    }
                    label="Select All"
                  />
                  <List>
                    {getAvailableEnterprises().map((enterprise) => (
                      <ListItem 
                        key={enterprise.partyId} 
                        dense 
                        button 
                        onClick={() => handleEnterpriseSelection(enterprise.partyId)}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              edge="start"
                              checked={selectedEnterprises.includes(enterprise.partyId)}
                              tabIndex={-1}
                              disableRipple
                            />
                          }
                          label={enterprise.enterpriseName}
                        />
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleSendInvites} 
            color="primary" 
            disabled={selectedEnterprises.length === 0 || isLoading || !selectedOrganization}
          >
            Send Invites
          </Button>
        </DialogActions>
      </Dialog>

      <GenericConfirmDialog
        open={confirmDialogOpen}
        onCancel={() => {
          setConfirmDialogOpen(false);
          setConnectionToTerminate(null);
        }}
        onConfirm={handleTerminateConnection}
        title="Confirm Termination"
        content={`Are you sure you want to terminate the connection with ${connectionToTerminate?.enterpriseName || ''}?`}
      />

      <CustomSnackbar ref={snackbarRef} />
    </>
  );
};

export default EnterpriseConnect;