import { Grid, Typography, List, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ArticleIcon from '@mui/icons-material/Article';

import UserGuide from "../../assets/documents/user_guide.pdf";

const TutorialPage = () => {

  const tutorials = [
    { title: 'User guide', document: UserGuide },
    { title: 'Field mapping drawing shapes explained', video: 'https://youtu.be/PoL1bWFivdQ' },
    { title: 'How to map a field', video: 'https://youtu.be/3wZYCM6i_MI' },
    { title: 'How to upload a field file', video: 'https://youtu.be/8cqHBjGXT80' },
    { title: 'Weather information on a farm', video: 'https://youtu.be/N94hHyXUW6M' },
    { title: 'How to create and manage activities', video: 'https://youtu.be/TQETeVrACVM' },
    { title: 'How to add an Organisation', video: 'https://youtu.be/_p7SZYzQoJ0' },
    { title: 'How to add a yield estimate note', video: 'https://youtu.be/RMxJFkprFbo' }
  ];

  return (

    <Grid container>
      <Grid item xs={12} sx={{ p: 2, pb: 0 }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Tutorials
        </Typography>
        <div
          style={{ 
            position: "absolute",
            top: 114,
            height: "calc(100vh - 190px)",
            padding: 0,
            width: "calc(100vw - 286px)",
            overflowY: "auto",
          }}
        >  
        <List>
          {tutorials.map((tutorial, index) => (
            <ListItemButton 
              key={index} 
              divider
              onClick={() => {
                if(tutorial.document) {
                  window.open(tutorial.document, '_blank');
                } else if(tutorial.video) {
                  window.open(tutorial.video, '_blank');
                }
              }}
            >
              {tutorial.document && (
                <ListItemIcon>
                  <ArticleIcon color="primary" />
                </ListItemIcon>
              )}
              {tutorial.video && (
                <ListItemIcon>
                  <PlayCircleOutlineIcon color="primary" />
                </ListItemIcon>
              )}
              <ListItemText primary={tutorial.title} />
            </ListItemButton>
          ))}
        </List>
        </div>
      </Grid>
    </Grid>  
  );
};

export default TutorialPage;
