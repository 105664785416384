import { useState, useEffect, useCallback } from "react";
import {
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import FieldMapComponent from "../molecules/FieldMapComponent";
import FarmFieldDisplay from "../organisms/farmDisplayCard";
import { getNdviDates } from "../../services/api-gs-service";
import { Controller, useForm } from "react-hook-form";
import { useConfig } from "../../context/ConfigContextTest";
import NDVIParameterRange from "../molecules/ndviScale";
import Dropdown from "../atom/dropdown";

const CropDataPage = () => {
  const location = useLocation();
  const initialFieldData = location.state?.fieldData;
  const [fieldData, setFieldData] = useState(initialFieldData);
  const [iframeUrl, setIframeUrl] = useState("");
  const [ndviDates, setNdviDates] = useState<any[]>([]);
  const { selectedOrganization } = useConfig();
  const [selectedFieldId, setSelectedFieldId] = useState("");
  const [selectedViewId, setSelectedViewId] = useState("");  

  const {
    control,
    setValue,
    handleSubmit,
  } = useForm({});

  let parameterList = [{ "value": 1, "label": "NDVI" }];
  
  const handleSetFieldId = useCallback((value: string) => {
    setSelectedFieldId(value);
  }, []);

  const cloudMaxCoverage = 5;

  useEffect(() => {
    if (location.state?.fieldData !== fieldData) {
      setFieldData(location.state?.fieldData);
    }
  }, [location.state?.fieldData, fieldData]);

  useEffect(() => {
    if (selectedFieldId) {
      fetchNdviDates(selectedFieldId);
    }
  }, [selectedFieldId]);

  const fetchNdviDates = async (fieldId) => {
    const dates = await getNdviDates(fieldId, cloudMaxCoverage);
    setNdviDates(dates);

    if (dates && dates.length > 0) {
      setValue("viewId", dates[0]?.viewId || "");
      setValue("parameterId", parameterList[0]?.value || "");
      setSelectedViewId(dates[0]?.viewId);
      handleGenerate(dates[0]?.viewId);  
    }
  };

  const handleDateChange = (event: SelectChangeEvent<string | number>) => {
    setValue("viewId", event.target.value);
    setSelectedViewId(String(event.target.value));
    handleGenerate(event.target.value);
  };

  const handleParameterChange = (event: SelectChangeEvent<string | number>) => {
    setValue("parameterId", event.target.value);
  };

  const handleGenerate = async (viewId) => {
    //setIsLoading(true);
    try {
      const eosViewId = viewId;
      const url = `${process.env.REACT_APP_MAPPING_TOOL}/ndvi/${selectedOrganization?.partyIdentifier}/${selectedFieldId}?EOSViewId=${eosViewId}`;
      setIframeUrl(url);
    } catch (error) {
      console.error("Failed to fetch EOSViewId:", error);
    } finally {
      //setIsLoading(false);
    }
  };

  return (
    
    <Grid container>
      
      <Grid item xs={12} sx={{ p: 2, pb: 0 }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Crop Insights
        </Typography>
      </Grid>

      <div
        className="filter-top-left"
        style={{ padding: 0, width: "400px", backgroundColor: "#ffffff" }}
      >
        <Grid item xs={12} container direction={"row"}>
            <Grid item xs={6} sx={{ pr: 1 }}>
              <Controller
                name="viewId"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    label={""}
                    name="viewId"
                    value={field.value || ""}
                    items={ndviDates?.map((date) => ({
                      value: date.viewId,
                      label: date.date,
                    }))}
                    disabled={ndviDates.length === 0}
                    onChange={(event) => {
                      handleDateChange(event);
                      field.onChange(event);
                    }}
                    sx={{ minWidth: 120 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="parameterId"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    label={""}
                    name="parameterId"
                    value={field.value || ""}
                    items={parameterList?.map((parameter) => ({
                      value: parameter.value,
                      label: parameter.label,
                    }))}
                    disabled={true}
                    onChange={(event) => {
                      handleParameterChange(event);
                      field.onChange(event);
                    }}
                    sx={{ minWidth: 120 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>        

        <Grid container>
          <Grid id="farm-field-display-wrapper" item xs={2.5}>
            <FarmFieldDisplay handleSetFieldId={handleSetFieldId} /> 
          </Grid>  
          <Grid item xs={9.5}>
            {iframeUrl && selectedFieldId && (
              <iframe
                //width="100%"
                //height="500px"
                style={{ border: 0, width: "100%", height: "calc(100vh - 134px)" }}
                title="Map"
                src={iframeUrl}
                aria-hidden="false"
              ></iframe>
            )}
            {(!selectedFieldId) && ( 
            <FieldMapComponent
              fieldData={fieldData}
              isFarmOverviewPage={false}
              isCropDataPage={true}
            />
            )}
            {selectedFieldId && (
              <div
                style={{ position: "absolute", top: "113px", right: "10px", backgroundColor: "white"}}
              >
              <NDVIParameterRange
                fieldId={selectedFieldId}
                eosViewId={selectedViewId}
              />
              </div>
            )}
          </Grid>
        </Grid>
    </Grid>
    
  );
};

export default CropDataPage;