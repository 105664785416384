import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Grid,
  styled,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import FormSection from "../molecules/DynamicFormSection";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  updateUserProfile,
  getUserProfile,
  setAzureUserId,
} from "../../services/api-ffm-service";
import { UserProfile } from "../../models/userProfile.interface";
import { useConfig } from "../../context/ConfigContextTest";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = z.object({
  givenName: z.string().min(1, "Given Name is required"),
  surname: z.string().min(1, "Surname is required"),
  displayName: z.string().min(1, "Display Name is required"),
  mobilePhone: z
    .string()
    .length(10, "Mobile Phone must be exactly 10 digits")
    .regex(phoneRegExp, "Please enter a valid Mobile Phone"),
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const UserProfileForm = () => {
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const { selectedOrganization } = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      givenName: userProfile?.givenName ?? "",
      surname: userProfile?.surname ?? "",
      displayName: userProfile?.displayName ?? "",
      mobilePhone: userProfile?.mobilePhone ?? "",
    },
  });

  useEffect(() => {
    setAzureUserId(selectedOrganization?.azureUserId);
    const fetchUserProfile = async () => {
      const data = await getUserProfile();
      if (data) {
        if (selectedOrganization) {
          const mobileContact = selectedOrganization.contactDetail[0]?.contacts.find(
            contact => contact.type === "Mobile"
          );
          if (mobileContact && !data.mobilePhone) {
            data.mobilePhone = mobileContact.details;
          }
        }
        
        setUserProfile(data);
        reset({
          givenName: data.givenName || "",
          surname: data.surname || "",
          displayName: data.displayName || "",
          mobilePhone: data.mobilePhone || "",
        });
      }
    };
    fetchUserProfile();
  }, [reset, modalOpen, selectedOrganization]);

  const handleFormSubmit = async (data) => {
    setIsLoading(true);
    try {
      data.azureUserId = userProfile ? userProfile?.userId : "";
      const response = await updateUserProfile(data);
      console.log("Profile Updated:", response);
      setModalOpen(false);
    } catch (error) {
      console.error("Failed to update user profile:", error);
    }
    setIsLoading(false);
  };
  const handleClose = () => {
    reset();
    setModalOpen(false);
  };
  const fieldDefinitions = {
    profileDetails: [
      { id: "givenName", label: "Name", type: "text" },
      { id: "surname", label: "Last Name", type: "text" },
      { id: "displayName", label: "Display Name", type: "text" },
      { id: "mobilePhone", label: "Contact Number", type: "text" },
    ],
  };

  return (
    <>
      <Tooltip title="Profile">
        <IconButton
          sx={{
            width: "30px",
            height: "30px",
          }}
          onClick={() => setModalOpen(true)}
          color="primary"
        >
          <ManageAccountsIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <StyledDialog
        onClose={handleClose}
        open={modalOpen}
        aria-labelledby="update-profile-dialog"
      >
        <DialogTitle id="update-profile-dialog">Update Profile</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <DialogContent dividers>
            <Grid container spacing={2} sx={{ padding: 2 }}>
              <FormSection
                fields={fieldDefinitions.profileDetails}
                control={control}
                errors={errors}
                columns={1}
                title=""
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<SaveIcon />}
              disabled={isLoading}
            >
              Update
              {isLoading && (
                <CircularProgress
                  color="inherit"
                  size={"15px"}
                  sx={{ ml: 2 }}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </StyledDialog>
    </>
  );
};
