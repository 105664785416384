import { useEffect } from "react";
import {
  Grid,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import FormSection from "../molecules/DynamicFormSection";
import DynamicFormDialog from "../molecules/dialog";
import GoogleAutoSearchBar from "../molecules/GoogleAutoSearchBar";
import { GoogleLocation } from "../../interfaces/googleLocation.interface";

interface OrganizationFormData {
  partyId?: number;
  contactName: string;
  vatNumber: string;
  contactSurname: string;
  contactNumber: string;
  contactEmail: string;
  orgName: string;
  registrationNumber: string;
  legalEntityTypeName?: string;
  physicalAddressLine1?: string;
  physicalAddressLine2?: string;
  physicalCity?: string;
  physicalCode?: string;
  selectedLocation?: GoogleLocation;
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = z.object({
    partyId: z.number().optional(),
    vatNumber: z.string().optional(),
    contactName: z.string().min(1, "Name is required"),
    contactSurname: z.string().min(1, "Surname is required"),
    contactNumber: z
      .string()
      .length(10, "Mobile Phone must be exactly 10 digits")
      .regex(phoneRegExp, "Please enter a valid Mobile Phone"),
    contactEmail: z.string().email("Invalid email address").min(1, "Email is required"),
    orgName: z.string().min(1, "Name is required"),
    registrationNumber: z.string().min(1, "Registration number is required"),
    legalEntityTypeName: z.string().optional(),
    physicalAddressLine1: z.string().optional(),
    physicalAddressLine2: z.string().optional(),
    physicalCity: z.string().optional(),
    physicalCode: z.string().optional(),
    selectedLocation: z
      .object({
        address: z.string(),
        coordinates: z.object({
          lat: z.number(),
          lng: z.number(),
        }),
        components: z.object({
          street: z.string(),
          city: z.string(),
          postalCode: z.string(),
        }),
      })
      .optional(),
  });  

const OrganizationDialog = ({
  isOpen,
  onClose,
  onSubmit,
  legalEntities,
  formData,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<OrganizationFormData>({
    resolver: zodResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      contactName: "",
      contactSurname: "",
      contactNumber: "",
      contactEmail: "",
      orgName: "",
      registrationNumber: "",
      vatNumber: "",
      legalEntityTypeName: legalEntities.length > 0 ? legalEntities[0].name : "",
      selectedLocation: undefined,
    },
  });

  useEffect(() => {
    if (isOpen && formData) {
      const initialValues: OrganizationFormData = {
        orgName: formData.name,
        registrationNumber: formData.registrationNumber || "",
        vatNumber: formData.vatNumber || "",
        contactName: formData.contactDetail[0]?.name || "",
        contactSurname: formData.contactDetail[0]?.surname || "",
        contactNumber: formData.contactDetail[0]?.contacts.find(contact => contact.type === "Mobile")?.details || "",
        contactEmail: formData.contactDetail[0]?.contacts.find(contact => contact.type === "Email")?.details || "",
        legalEntityTypeName:
          formData.legalEntityTypeName ||
          (legalEntities.length > 0 ? legalEntities[0].name : ""),
        selectedLocation: undefined,
        physicalAddressLine1: formData.physicalAddress[0]?.addressLine1 || "",
        physicalAddressLine2: formData.physicalAddress[0]?.addressLine2 || "",
        physicalCity: formData.physicalAddress[0]?.city || "",
        physicalCode: formData.physicalAddress[0]?.code || "",
      };

      if (formData.physicalAddress && formData.physicalAddress.length > 0) {
        const physicalAddress = formData.physicalAddress[0];
        const [lat, lng] = physicalAddress.addressLine2.split(",").map(coord => parseFloat(coord.trim()));
  
        initialValues.selectedLocation = {
          address: physicalAddress.addressLine1,
          coordinates: { lat, lng },
          components: {
            street: physicalAddress.addressLine1,
            city: physicalAddress.city,
            postalCode: physicalAddress.code,
          },
        };
      }
  
      reset(initialValues);
    }

    return () => {
      reset({
        contactName: "",
        contactSurname: "",
        contactNumber: "",
        contactEmail: "",
        orgName: "",
        physicalAddressLine1: "",
        physicalAddressLine2: "",
        physicalCity: "",
        physicalCode: "",
        vatNumber: "",
        registrationNumber: "",
        legalEntityTypeName: legalEntities.length > 0 ? legalEntities[0].name : "",
        selectedLocation: undefined,
      });
    };
  }, [formData, isOpen, legalEntities, reset]);

  const handleClose = () => {
    reset({
      contactName: "",
      contactSurname: "",
      contactNumber: "",
      contactEmail: "",
      orgName: "",
      physicalAddressLine1: "",
      physicalAddressLine2: "",
      physicalCity: "",
      physicalCode: "",
      vatNumber: "",
      registrationNumber: "",
      legalEntityTypeName: legalEntities.length > 0 ? legalEntities[0].name : "",
      selectedLocation: undefined,
    });
    onClose();
  };

  const handleLocationChange = (location: GoogleLocation) => {
    setValue("selectedLocation", location);
  };

  const fieldDefinitions = {
    organizationDetails: [
      { id: "orgName", label: "Organisation Name", type: "text" },
      { id: "vatNumber", label: "VAT Number", type: "text" },
      { id: "registrationNumber", label: "Registration Number", type: "text" },
      {
        id: "legalEntityTypeName",
        label: "Legal Entity Type",
        type: "select",
        options: legalEntities.map((entity) => ({
          label: entity.name,
          value: entity.name,
        })),
      },
    ],
    contact: [
      { id: "contactName", label: "Name", type: "text" },
      { id: "contactSurname", label: "Surname", type: "text" },
      { id: "contactNumber", label: "Contact Number", type: "text" },
      { id: "contactEmail", label: "Email", type: "email" },
    ],
  };

  const formContent = (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers sx={{ pt: 1, pb: 1 }}>
          <Grid container spacing={2} sx={{ padding: 2 }}>
            <FormSection
              title="Organisation Details"
              fields={fieldDefinitions.organizationDetails}
              control={control}
              errors={errors}
              columns={2}
            />
            <FormSection
              title="Contact Details"
              fields={fieldDefinitions.contact}
              control={control}
              errors={errors}
              columns={2}
            />
            <Grid item xs={12}>
              <Typography variant="subtitle1">Location</Typography>
            </Grid>
            <Controller
              name="selectedLocation"
              control={control}
              render={({ field }) => (
                <GoogleAutoSearchBar
                  initialLocation={field.value}
                  onLocationChange={(location) => {
                    const updatedLocation: GoogleLocation = {
                      ...location,
                      components: {
                        street: location.components?.street || "",
                        city: location.components?.city || "",
                        postalCode: location.components?.postalCode || "",
                      },
                    };
                    field.onChange(location.address);
                    handleLocationChange(updatedLocation);
                  }}
                />
              )}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            startIcon={<SaveIcon />}
          >
            {formData ? "Save Changes" : "Save"}

          </Button>
        </DialogActions>
      </form>
    </>
  );

  return (
    <DynamicFormDialog
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      title={formData ? "Update Organisation" : "Add Organisation"}
      formContent={formContent}
    />
  );
};

export default OrganizationDialog;