import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import DateSelector from "../atom/dateSelect";
import { useForm, Controller } from "react-hook-form";
import { fetchData } from "../../hooks/useFetchData";
import { getActivitiesSummary } from "../../services/api-ffm-service";
//import { getActivitySummaryReport } from "../../services/api-ffm-service";
import ActivitySummaryReport from "./activitySummaryReport";
import { useConfig } from "../../context/ConfigContextTest";
import MultiSelectAll from "../molecules/MultiSelectAll";
import dayjs from "dayjs";

type FormValues = {
  startDate: string;
  endDate: string;
  activityCategories: string[];
  fields: string[];
};

const ActivitySummary: React.FC = () => {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
      activityCategories: [],
      fields: [],
    },
  });

  const [showSummary, setShowSummary] = useState(false);
  const [activitiesSummary, setActivitiesSummary] = useState<any>({});
  const { selectedOrganization, mappedfields, activityCategories } = useConfig();
  const [formData, setFormData] = useState<FormValues | null>(null);
  const [dateToday, setDateToday] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedActivityCategoryIds, setSelectedActivityCategoryIds] = useState<string[]>([]);
  const [selectedFieldIds, setSelectedFieldIds] = useState<string[]>([]);
  const [selectedActivityCategoryLabels, setSelectedActivityCategoryLabels] = useState<string[]>([]);
  const [selectedFieldLabels, setSelectedFieldLabels] = useState<string[]>([]);
  
  // // runs once after initial render
  // useEffect(() => {
  //   //console.log('entry useEffect ActivityCategories');
  //   //console.log(activityCategories);
  //   const pIds: string[] = [];
  //   activityCategories.forEach(category => {
  //     pIds.push(String(category.activityCategoryId));
  //   });
  //   setSelectedActivityCategoryIds(pIds);
  //   setSelectedActivityCategoryLabels(["All"]);
  // },  []);


  // // runs once after initial render
  // useEffect(() => {
  //   const pIds: string[] = [];
  //   mappedfields.forEach(mfield => {
  //     pIds.push(String(mfield.fieldId));
  //   });
  //   setSelectedFieldIds(pIds);
  //   setSelectedFieldLabels(["All"]);
  // }, []);

  useEffect(() => {
    const pIds = activityCategories.map(category => String(category.activityCategoryId));
    setSelectedActivityCategoryIds(pIds);
    setSelectedActivityCategoryLabels(["All"]);
  }, [activityCategories]);

  useEffect(() => {
    const pIds = mappedfields.map(mfield => String(mfield.fieldId));
    setSelectedFieldIds(pIds);
    setSelectedFieldLabels(["All"]);
  }, [mappedfields]);

  /*
  const sdWatched = watch(['startDate']);

  useEffect(() => {
    if(showSummary) {
      const subscription = watch((value, { name, type }) =>
        console.log(value, name, type)
      );
      console.log('showSummary');
      console.log(showSummary);
     
      return () => subscription.unsubscribe();
    }
  }, [sdWatched]);
*/  

  const handleGenerateSummary = async (data: FormValues) => {
    setIsLoading(true);
/*
    console.log('selectedFieldIds');
    console.log(selectedFieldIds);

    console.log('selectedActivityCategoryIds');
    console.log(selectedActivityCategoryIds);

    console.log('selectedFieldLabels');
    console.log(selectedFieldLabels);

    console.log('selectedActivityCategoryLabels');
    console.log(selectedActivityCategoryLabels);
*/
    const today = dayjs().format("YYYY/MM/DD");//getFormattedDate();
    setDateToday(today);
    
    setFormData(data);

    const formattedData = {
      startDate: data.startDate,
      endDate: data.endDate,
      azureUserId: selectedOrganization?.azureUserId,
      organizationId: selectedOrganization?.organizationId,
      activityCategory: selectedActivityCategoryIds,
      fields: selectedFieldIds,
    };
    
    await fetchData(getActivitiesSummary, setActivitiesSummary, undefined, [
      formattedData,
    ]);
    
    /*
    const [activitySummary, setActivitySummary] = useState<ActivitySummaryReport[]>([]);
    await fetchData(getActivitySummaryReport, setActivitySummary, undefined, [
      selectedOrganization?.organizationId,
      data.startDate,
      data.endDate,
      "FieldName",
      "DESC"
    ]);
    */

    setShowSummary(true);
    setIsLoading(false);

  };
  
  return (
    <Grid sx={{ p: 1, pt: 0 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Activity Summary Report
      </Typography>

      <form onSubmit={handleSubmit(handleGenerateSummary)}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DateSelector
                  label="Start Date"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DateSelector
                  label="End Date"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="activityCategories"
              control={control}
              render={({ field }) => (
                <MultiSelectAll
                  label="Activity Category"
                  options={activityCategories.map((category) => ({
                    id: category.activityCategoryId,
                    label: category.name,
                  }))}
                  fieldId="categories"
                  error={undefined}
                  helperText={undefined}
                  sx={undefined}
                  setIdSelection={setSelectedActivityCategoryIds}
                  setLabelSelection={setSelectedActivityCategoryLabels}
                  preselected={() => activityCategories.map(category => category.activityCategoryId)}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="fields"
              control={control}
              render={({ field }) => (
                <MultiSelectAll
                  label="Fields"
                  options={mappedfields.map((option) => ({
                    id: option.fieldId,
                    label: option.name,
                  }))}
                  fieldId="fields"
                  error={undefined}
                  helperText={undefined}
                  sx={undefined}
                  setIdSelection={setSelectedFieldIds}
                  setLabelSelection={setSelectedFieldLabels}
                  preselected={() => mappedfields.map(mfield => mfield.fieldId)}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} style={{ marginTop: 10 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Generate
              {isLoading && (
                <CircularProgress
                  color="inherit"
                  size={"15px"}
                  sx={{ ml: 2 }}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      {showSummary && formData && (
        <Grid item xs={12}>
          <ActivitySummaryReport
            selectedCategories={selectedActivityCategoryLabels}
            selectedFields={selectedFieldLabels}
            dateRange={`${formData.startDate.replace(/-/g, "/")} - ${formData.endDate.replace(/-/g, "/")}`}
            activities={activitiesSummary?.activities}
            date={dateToday}
            totalCost={0}
            orgName={selectedOrganization?.name}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ActivitySummary;