import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_CAR_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

//CROPS CRUD APIs
export const getCrops = async (): Promise<any> => {
  try {
    const response = await api.get<any>("crops?Is_Active=1");

    return response.data || [];
  } catch (error: any) {
    throw new Error(`Error while the retreiving: ${error.message}`);
  }
};

export const getCultivars = async (cropId: number): Promise<any> => {
  try {
    const response = await api.get<any>(`varietals?CropId=${cropId}`);

    return response.data || [];
  } catch (error: any) {
    throw new Error(`Error while the retreiving: ${error.message}`);
  }
};

export const getProducts = async (): Promise<any> => {
  try {
    const response = await api.get<any>("productffp");

    return response.data || [];
  } catch (error: any) {
    throw new Error(`Error while the retreiving: ${error.message}`);
  }
};
