import React, { useEffect, useState, useRef } from "react";
import { getOrganizations,setAzureUserId } from "../../services/api-ffm-service";
import { useLocation } from "react-router-dom";
import { fetchData } from "../../hooks/useFetchData";
import { useConfig } from "../../context/ConfigContextTest";

import { Box } from "@mui/material";
import WeatherForecastDialog from "../organisms/weatherForecastDialog";
import { useMsal } from "@azure/msal-react";

interface FieldMapProps {
  fieldData?: any;
  onLoad?: () => void;
  height?: any;
  isFarmOverviewPage?: boolean;
  isCropDataPage?: boolean;
  updateTrigger?: number;
}

const FieldMapComponent: React.FC<FieldMapProps> = ({
  fieldData,
  isFarmOverviewPage,
  isCropDataPage,
  updateTrigger,
}) => {
  const { selectedOrganization } = useConfig();
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [mapUrl, setMapUrl] = useState<string>("");
  const { instance } = useMsal();
  const [coords, setCoords] = useState({ long: null, lat: null });

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const fieldId = fieldData?.fieldId;
  const location = useLocation();

  useEffect(() => {
    fetchData(getOrganizations, setOrganizations);
  }, []); 
   

  useEffect(() => {
    
    const pathnames = location.pathname.split("/").filter((x) => x);
    const lastSegment = pathnames[pathnames.length - 1];

    let azureUserId = instance.getActiveAccount()?.localAccountId;

    if (azureUserId) {
      setAzureUserId(azureUserId);
    }

    let newMapUrl = "";
    let lastEdittedUrl = "";


    if(isCropDataPage) {
      console.log('isCropDataPage branch');
      const isSearch = 0;
      if (selectedOrganization) {
        newMapUrl = `${process.env.REACT_APP_MAPPING_TOOL}/field/${selectedOrganization.partyIdentifier}/view/all?azureUserId=${azureUserId}&isSearch=${isSearch}`;
      } else if (organizations.length > 0) {
        newMapUrl = `${process.env.REACT_APP_MAPPING_TOOL}/field/${organizations[0].partyIdentifier}/view/all?azureUserId=${azureUserId}&isSearch=${isSearch}`;
      }
    } else if (lastSegment === "fields" && fieldId) {
      console.log('fields and fieldId branch');
      newMapUrl = `${process.env.REACT_APP_MAPPING_TOOL}/field/${selectedOrganization?.partyIdentifier}/${fieldId}?azureUserId=${azureUserId}`;
    } else if (!lastSegment && selectedOrganization) {
      console.log('No lastSegment and selectedOrganization branch');
      newMapUrl = `${process.env.REACT_APP_MAPPING_TOOL}/field/${selectedOrganization.partyIdentifier}/view/all?azureUserId=${azureUserId}`;
      lastEdittedUrl = `${process.env.REACT_APP_MAPPING_TOOL}/field/${selectedOrganization.partyIdentifier}/lastEditted`;
    } else if (selectedOrganization) {
      console.log('selectedOrganization branch');
      newMapUrl = `${process.env.REACT_APP_MAPPING_TOOL}/field/${selectedOrganization.partyIdentifier}?azureUserId=${azureUserId}`;
    } else if (organizations.length > 0) {
      console.log('organizations[0] branch');
      newMapUrl = `${process.env.REACT_APP_MAPPING_TOOL}/field/${organizations[0].partyIdentifier}?azureUserId=${azureUserId}`;
    }

    console.log('Generated newMapUrl:', newMapUrl);

    if (updateTrigger !== undefined && updateTrigger > 0) {
      console.log('Refreshing map due to updateTrigger:', updateTrigger);
      newMapUrl = `${newMapUrl}${newMapUrl.includes('?') ? '&' : '?'}t=${Date.now()}`;
      console.log('Refreshed URL:', newMapUrl);
    }

    setMapUrl(newMapUrl);

    if (iframeRef.current) {
      iframeRef.current.src = newMapUrl;
    }

    if (selectedOrganization) {
      lastEdittedUrl = `${process.env.REACT_APP_MAPPING_TOOL}/field/${selectedOrganization.partyIdentifier}/lastEditted`;

      fetch(lastEdittedUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const coordinates = JSON.parse(data.Coords);
        const [long, lat] = coordinates[0][0];
        setCoords({ lat, long });
      })
      .catch(error => {
        console.error('There was an error with the fetch operation:', error);
      });
    }

  }, [location, fieldId, selectedOrganization, organizations, instance, isCropDataPage, updateTrigger]);

  return (
    <div style={{ position: "relative" }} >
      <iframe
        ref={iframeRef}
        style={{ border: 0, width: "100%", height: "calc(100vh - 134px)" }}
        title="Map"
        src={mapUrl}
        aria-hidden="false"
      ></iframe>
      {isFarmOverviewPage && coords.lat && coords.long && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 10,
          }}
        >
          <WeatherForecastDialog 
            lat={coords.lat} 
            long={coords.long} 
          />
        </Box>
      )}
    </div>
  );
};

export default FieldMapComponent;