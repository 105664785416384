import { useState } from "react";
import "./App.css";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Box, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme";
import LogoutPage from "./components/pages/loggedOut";
import SignUp from "./components/organisms/signUp/signUpPage";
import { ConfigProvider } from "./context/ConfigContextTest";
import AppContent from "./components/organisms/AppContent";
import FFspaPage from "./ffSPA/ffSPA";
import { PopupProvider } from './context/PopupContext';
import FieldMappingPopup from './components/organisms/FieldMappingPopup';

const App = ({ instance }) => {
  const [open, setOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState<
    "logout" | "signUp" | "ffLanding"
  >("ffLanding");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSignUpClick = () => {
    setCurrentPage("signUp");
  };

  const handleCancelClick = () => {
    setCurrentPage("logout");
  };

  return (
    <ThemeProvider theme={theme}>
      <MsalProvider instance={instance}>
        <ConfigProvider>
          <PopupProvider>
            <UnauthenticatedTemplate>
              <Box
                style={{
                  backgroundColor: theme.palette.primary.main,
                  minHeight: "111vh",
                }}
              >
                {currentPage === "ffLanding" && (
                  <FFspaPage onSignUpClick={handleCancelClick} />
                )}
                {currentPage === "logout" && (
                  <LogoutPage onSignUpClick={handleSignUpClick} />
                )}
                {currentPage === "signUp" && (
                  <SignUp onCancelClick={handleCancelClick} />
                )}
              </Box>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <BrowserRouter>
                <AppContent
                  open={open}
                  handleDrawerOpen={handleDrawerOpen}
                  handleDrawerClose={handleDrawerClose}
                />
                <FieldMappingPopup />
              </BrowserRouter>
            </AuthenticatedTemplate>
          </PopupProvider>
        </ConfigProvider>
      </MsalProvider>
    </ThemeProvider>
  );
};

export default App;