import axios from "axios";

// Set up the axios instance with the base URL and default headers
const api = axios.create({
  baseURL: process.env.REACT_APP_MAPPING_TOOL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getNdviParameterRange = async (): Promise<any> => {
  try {
    const response = await api.get<any>("ndvi/internal/parameter/range", {});
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch fields: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error("Something went wrong while fetching fields", error);
      return null;
    }
  }
};

export const getNdviMetadata = async (
  eosLocationCropperRefId: number,
  eosViewId: string
): Promise<any> => {
  try {
    const response = await api.get<any>("ndvi/internal/metadata", {
      params: { eosLocationCropperRefId, eosViewId },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch fields: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error("Something went wrong while fetching fields", error);
      return null;
    }
  }
};

export const getNdviData = async (
  eosLocationCropperRefId: number,
  eosViewId: string,
  zoom: number
): Promise<any> => {
  try {
    const response = await api.get<any>("ndvi/internal/data", {
      params: { eosLocationCropperRefId, eosViewId, zoom },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch fields: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error("Something went wrong while fetching fields", error);
      return null;
    }
  }
};

export const getFieldIcon = async (coordinates: string): Promise<any> => {
  try {
    const response = await api.get<any>("afrigis/tile/fieldicon", {
      params: { coordinates },
    });
    if (response.data.icon === null || response.data.icon[0]  === "") {
      return createFallbackImage();
    }
    return response.data.icon;
  } catch (error: any) {
    console.error("Error fetching field icon:", error);
    // Return a fallback image if an error occurs
    return createFallbackImage();
  }
};

const createFallbackImage = (): string => {
  // Create a canvas element
  const canvas = document.createElement("canvas");
  canvas.width = 256;
  canvas.height = 256;

  const ctx = canvas.getContext("2d");

  // Check if the context is null
  if (!ctx) {
    throw new Error("Failed to get canvas context"); // Handle the error appropriately
  }

  // Set the fill color and create the image
  ctx.fillStyle = "rgba(60, 79, 30, 1)"; // Example color
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Create the image as a PNG and get the data URL
  const dataURL = canvas.toDataURL("image/jpeg");

  // Extract the base64 part from the data URL (removing "data:image/png;base64,")
  const base64 = dataURL.split(",")[1];

  return base64; // Return the base64 string
};
