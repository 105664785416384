import React from "react";
import { Pagination, Card, Grid, Typography } from "@mui/material";

interface TableData {
  id: string;
  [key: string]: any;
}

interface ColumnConfig {
  label: string;
  dataKey: keyof TableData;
  renderCell: (item: TableData) => React.ReactNode;
}

interface DynamicTableProps {
  data: TableData[];
  columns: ColumnConfig[];
  rowsPerPage: number;

  notes: Boolean;
}

const CardList: React.FC<DynamicTableProps> = ({
  data,
  columns,
  rowsPerPage,
  notes,
}) => {

/*  
  console.log('cardTiles, data');
  console.log(data);
  console.log('cardTiles, columns');
  console.log(columns);
  console.log('cardTiles, rowsPerPage');
  console.log(rowsPerPage);
  console.log('cardTiles, notes');
  console.log(notes);
*/  



  const [page, setPage] = React.useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const renderTypographyNotes = (index, column, item) => {
    switch (index) {
      case 0:
        return (
          <Grid item xs={12} key={`${item.id}-${column.dataKey}`}>
            <Typography variant="h6" gutterBottom>
              <strong>{column.renderCell(item)}</strong>
            </Typography>
          </Grid>
        );
      case 1:
        return (
          <Grid xs={12}>
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              gutterBottom
            >
              {column.renderCell(item)}
            </Typography>
          </Grid>
        );
      case 5:
        return (
          <Grid xs={6}>
            <Typography variant="caption">{column.renderCell(item)}</Typography>
          </Grid>
        );
      case 6:
        return (
          <Grid
            xs={6}
            sx={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              alignContent: "right",
            }}
          >
            {column.renderCell(item)}
          </Grid>
        );
      default:
        return (
          <Grid xs={4} sx={{ pr: 0.5 }}>
            <Typography variant="caption" gutterBottom>
              {column.renderCell(item)}
            </Typography>
          </Grid>
        );
    }
  };
  const renderTypographyDoc = (index, column, item) => {
    switch (index) {
      case 0:
        return (
          <Grid xs={12}>
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              gutterBottom
            >
              {column.renderCell(item)}
            </Typography>
          </Grid>
        );
      case 1:
        return (
          <Grid item xs={12} key={`${item.id}-${column.dataKey}`}>
            <Typography variant="h6" gutterBottom>
              <strong>{column.renderCell(item)}</strong>
            </Typography>
          </Grid>
        );
      case 4:
        return (
          <Grid xs={6}>
            <Typography variant="caption">{column.renderCell(item)}</Typography>
          </Grid>
        );
      case 5:
        return (
          <Grid 
            xs={6}
            sx={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              alignContent: "right",        
            }}
          >
            {column.renderCell(item)}
          </Grid>
        );
      default:
        return (
          <Grid xs={12} sx={{ pr: 0.5 }}>
            <Typography variant="caption" gutterBottom>
              {column.renderCell(item)}
            </Typography>
          </Grid>
        );
    }
  };
  return (
    <>
      <Grid container>
        {data
          .slice((page - 1) * rowsPerPage, page * rowsPerPage)
          .map((item) => (
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ m: 1, p: 1, border: '1px solid #506135', borderRadius: '8px' }} >
                <Grid container key={item.id}>
                  {columns.map((column, index) =>
                    notes
                      ? renderTypographyNotes(index, column, item)
                      : renderTypographyDoc(index, column, item)
                  )}
                </Grid>
              </Card>
            </Grid>
          ))}
      </Grid>
      <Pagination
        sx={{ marginTop: 2 }}
        count={Math.ceil(data.length / rowsPerPage)}
        page={page}
        onChange={handleChange}
      />
    </>
  );
};

export default CardList;
