import proj4, { InterfaceProjection } from "proj4";
import { getNdviData } from "../services/api-mt-service";

const convertCoordinatesToMVCArray = (coordinates: [number, number][][]): google.maps.MVCArray<google.maps.LatLng> => {
  const mvcArray = new google.maps.MVCArray<google.maps.LatLng>();

  coordinates.forEach((nestedArray) => {
    nestedArray.forEach(([lng, lat]) => {
      //console.log('ffp2 convertCoordinatesToMVCArray, lat,lng');
      //console.log(lat+','+lng);
      const latLng = new google.maps.LatLng(lat, lng);
      mvcArray.push(latLng);
    });
  });

  return mvcArray;
};

export const calculateFieldBounds = (coordinates: string | [string | number, string | number][][]): google.maps.LatLngBounds => {
  const polygonBounds = new google.maps.LatLngBounds();

  let parsedCoordinates: [number, number][][] = [];

  // If the input is a string, try to parse it into a coordinates array
  if (typeof coordinates === "string") {
    try {
      parsedCoordinates = JSON.parse(coordinates) as [number, number][][];
    } catch (error) {
      console.error("Invalid string format for coordinates:", error);
      return polygonBounds;
    }
  } else {
    // Otherwise, use the provided coordinates if they are already in correct format
    parsedCoordinates = coordinates as [number, number][][];
  }

  // Loop through the parsed coordinates to extend bounds
  parsedCoordinates.forEach(polygon => {
    polygon.forEach(vertex => {
      // Parse string values into numbers if they are strings
      const lat = typeof vertex[1] === 'string' ? parseFloat(vertex[1]) : vertex[1];
      const lng = typeof vertex[0] === 'string' ? parseFloat(vertex[0]) : vertex[0];

      // Check if parsing was successful
      if (!isNaN(lat) && !isNaN(lng)) {
        const latLng = new google.maps.LatLng(lat, lng);
        polygonBounds.extend(latLng);
      } else {
        console.error("Invalid coordinate:", vertex);
      }
    });
  });

  return polygonBounds;
};

export const getBoundsCenter = (bounds: google.maps.LatLngBounds) => {
  const centerLat = (bounds.getNorthEast().lat() + bounds.getSouthWest().lat()) / 2;
  const centerLng = (bounds.getNorthEast().lng() + bounds.getSouthWest().lng()) / 2;

  return { lat: centerLat, lng: centerLng };
};


export const calculateArea = (coordinates: [number, number][][]): number => {
  if (!window.google || !google.maps) {
    throw new Error("Google Maps API is not loaded.");
  }

  //console.log('calculateArea, coordinates');
  //console.log(coordinates);


  const mvcArray = convertCoordinatesToMVCArray(coordinates);
  //console.log('mvcArray');
  //console.log(mvcArray.getLength());
  const areaInSquareMeters = google.maps.geometry.spherical.computeArea(mvcArray);
  //console.log('ffp2, calculateArea, areaInSquareMeters');
  //console.log(areaInSquareMeters);

  return areaInSquareMeters;
};

// export const convertToHectares = (areaInSquareMeters: number): string => {
//   const areaInHectares = areaInSquareMeters * 0.0001;
//   return `${areaInHectares.toFixed(2)} ha`;
// };

// export const calculateHectares = (coordinates: [number, number][][]): string => {
//   const areaInSquareMeters = calculateArea(coordinates);
//   return convertToHectares(areaInSquareMeters);
// };

export async function getNDVIData(
  eosLocationCropperRefId: number,
  eosViewId: string,
  zoom: number,
  parameterRange: any[]
): Promise<void> {
  const response = await getNdviData(eosLocationCropperRefId, eosViewId, zoom);
  console.log("response");
  console.log(response);
  const fpsjson = JSON.parse(response.data);
  const parameterData = fpsjson.data;
  const isHa: boolean = true;

  const fieldPolygonCoords = fpsjson.Coords
  const fieldAreaHa = calculateArea(fieldPolygonCoords);

  const legendContainer = document.getElementsByClassName('legend-wrapper')[0] as HTMLElement;

  let histogramChart = document.getElementById("histogram-chart");
  if (histogramChart !== undefined && histogramChart !== null) {
    histogramChart.remove();
  }

  if (parameterData.length > 0) {
    // const dataArray: Array<any> = [] 
    const simpleDataArray: Array<number> = [];
    let dataValue: number;
    // const source = proj4('EPSG:3857');
    // const dest = proj4('EPSG:4326');

    parameterData.forEach((v: any) => {
      const parameterRecord = v.NDVI;
      let parameterIndex = 0;

      const parameterDataValuesText = v.Text.Value;
      const parameterDataValues = parameterDataValuesText.split(',');
      let ndviArray: Array<number>;
      const ndviArrays: Array<Array<number>> = [];

      const source = proj4('EPSG:3857') as InterfaceProjection;
      const dest = proj4('EPSG:4326') as InterfaceProjection;

      for (let y = 0; y < parameterRecord.Rows; y++) {
        ndviArray = [];
        for (let x = 0; x < parameterRecord.Columns; x++) {
          if (parameterDataValues[parameterIndex].indexOf('nan') === -1) {
            ndviArray.push(parseFloat(parameterDataValues[parameterIndex]));
          } else {
            ndviArray.push(-9999);
          }
          parameterIndex++;
        }
        ndviArrays.push(ndviArray);
      }

      const isFullView = false;
      //let cnt = 0;
      for (let y = 0; y < parameterRecord.Rows; y++) {
        for (let x = 0; x < parameterRecord.Columns; x++) {
          const p = {
            x: parameterRecord.OriginX + 10 * x * Math.pow(2, 17 - zoom),
            y: parameterRecord.OriginY - 10 * y * Math.pow(2, 17 - zoom)
          };
          const res = proj4.transform(source, dest, p); // convert to latLng 

          const point = { lat: res.y, lng: res.x };

          if (isFullView) {
            dataValue = parseFloat(String(ndviArrays[y][x]));
            simpleDataArray.push(parseFloat(dataValue.toFixed(3))); // for histogram
          } else {
            // Check if the point is inside the polygon
            if (isPointInPolygon(point, fieldPolygonCoords)) {
              dataValue = parseFloat(String(ndviArrays[y][x]));
              simpleDataArray.push(parseFloat(dataValue.toFixed(3))); // for histogram
              //cnt++;
            }
          }
        }
      }
    });

    simpleDataArray.sort((a, b) => a - b);

    let palette;
    if (parameterRange[0].Value !== 0) {
      palette = parameterRange.reverse();
    }

    const histogram: Array<number> = [];
    parameterRange.forEach(() => {
      histogram.push(0);
    });

    simpleDataArray.forEach((val: number) => {
      palette.forEach((r: any, ri: number) => {
        if (ri < palette.length - 1) {
          if (val >= r.Value && val < palette[ri + 1].Value) {
            histogram[ri]++;
          }
        } else if (ri === palette.length - 1) {
          if (val >= r.Value) {
            histogram[ri]++;
          }
        }
      });
    });

    const histogramPct: Array<number> = [];
    histogram.forEach((h: number) => {
      const pctValue = (100 * h) / simpleDataArray.length;
      histogramPct.push(parseFloat(pctValue.toFixed(2)));
    });

    const dh = 224 / parameterRange.length;

    let paletteHtml = '';
    paletteHtml += '<div id="histogram-chart" style="left: 60px; position: absolute; top: 34px;">';
    paletteHtml += '<div style="position: relative;">';

    histogramPct.reverse();
    palette.reverse();

    let haVal: number;

    histogramPct.forEach((r: number, ri: number) => {
      // const dw = (40 * r) / 100;
      const dt = ri * dh;
      const pt = (dh - 11) / 2 - 1;
      paletteHtml += `<div id="bar_${ri}" style="position: absolute; top: ${dt}px; left: 0px; width: 0px; height: ${dh}px; background-color: ${palette[ri].Color};"></div>`;
      if (isHa) {
        if (parseFloat(String(r)) === 0) {
          paletteHtml += `<div id="val_${ri}" style="position: absolute; top: ${dt + pt}px; left: 5px; width: 50px; height: ${dh}px; color: #888888; font-size: 10px;">0 ha</div>`;
        } else {
          haVal = (parseFloat(String(r)) * fieldAreaHa) / 100;
          paletteHtml += `<div id="val_${ri}" style="position: absolute; top: ${dt + pt}px; left: 5px; width: 50px; height: ${dh}px; color: #888888; font-size: 10px;">${haVal.toFixed(2)} ha</div>`;
        }
      } else {
        paletteHtml += `<div id="val_${ri}" style="position: absolute; top: ${dt + pt}px; left: 5px; width: 50px; height: ${dh}px; color: #888888; font-size: 10px;">${r}%</div>`;
      }
    });
    paletteHtml += '</div>';
    paletteHtml += '</div>';

    legendContainer.innerHTML += paletteHtml;

    // the animation
    histogramPct.forEach((r: number, ri: number) => {
      const chartBar = document.getElementById(`bar_${ri}`) as HTMLElement;
      const chartVal = document.getElementById(`val_${ri}`) as HTMLElement;
      const dw = (40 * r) / 100;

      const chartBarKeyframes = new KeyframeEffect(
        chartBar,
        [
          { width: '0px' },
          { width: `${dw}px` }
        ],
        { duration: 800, fill: "forwards", easing: "ease" }
      );

      const chartValKeyframes = new KeyframeEffect(
        chartVal,
        [
          { left: '5px' },
          { left: `${dw + 5}px` }
        ],
        { duration: 800, fill: "forwards", easing: "ease" }
      );

      const chartBarAnimation = new Animation(chartBarKeyframes, document.timeline);
      const chartValAnimation = new Animation(chartValKeyframes, document.timeline);

      chartBarAnimation.play();
      chartValAnimation.play();
    });
  }
}


function isPointInPolygon(point: { lat: number; lng: number }, polygon: { lat: number; lng: number }[]): boolean {
  let isInside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].lng, yi = polygon[i].lat;
    const xj = polygon[j].lng, yj = polygon[j].lat;

    const intersect = ((yi > point.lat) !== (yj > point.lat)) &&
      (point.lng < (xj - xi) * (point.lat - yi) / (yj - yi) + xi);
    if (intersect) isInside = !isInside;
  }
  return isInside;
}