import React, { useState, useRef } from "react";
import { Box, Grid, Button, Typography, AlertColor } from "@mui/material";
import {
  Add as AddIcon,
} from "@mui/icons-material";
import ActionButtons from "../molecules/actionButtons";
import OrganizationDialog from "../organisms/organisationDialog";
import NotificationDialog from "../organisms/notificationDialog";
import DynamicTable from "../organisms/tableStyled";
import {
  getOrganizations,
  deleteOrganization,
  createOrganization,
  updateOrganization,
  getLegalEntities,
} from "../../services/api-ffm-service";
import { LegalEntity } from "../../models/legalEntity.interface";
import GenericConfirmDialog from "../organisms/genericConfirmDialog";
import { Contacts } from "../../models/contacts.interface";
import { fetchData, useFetchData } from "../../hooks/useFetchData";
import { handleApiResponse } from "../../utils/Utilities";
import CustomSnackbar from "../atom/CustomSnackbar";
import { useConfig } from "../../context/ConfigContextTest";
import { GoogleLocation } from "../../interfaces/googleLocation.interface";

interface DataItem {
  id: string;
  [key: string]: any;
}

interface ColumnConfig {
  label: string;
  dataKey: keyof DataItem;
  renderCell: (item: DataItem) => React.ReactNode;
}

  
const OrganizationSettings: React.FC = () => {
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage] = useState("");
  const [notificationTitle] = useState("");

  const [legalEntities, setLegalEntities] = useState<LegalEntity[]>([]);
  const { setSelectedOrganization, refreshOrganizations, selectedOrganization } = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<any | null>(null);
  const snackbarRef = useRef<{
    handleOpen: (title: string, msg: string | null, sev: AlertColor) => void;
  }>(null);
  useFetchData(getOrganizations, setOrganizations, setIsLoading);
  useFetchData(getLegalEntities, setLegalEntities, setIsLoading);

  const handleOpenForm = () => {
    setSelectedOrg(null);
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setSelectedOrg(null);
    setFormOpen(false);
    setIsLoading(false);
    setConfirmOpen(false);
  };

  const handleEdit = (org) => {
    setSelectedOrg(org);
    setFormOpen(true);
  };

  const handleDelete = (org) => {
    setSelectedOrg(org);
    setConfirmOpen(true);
  };

  const showSnackbar = (title, message, severity) => {
    if (snackbarRef.current) {
      snackbarRef.current.handleOpen(title, message, severity);
    }
  };

  const handleSubmit = async (formData: any) => {
    setFormOpen(false);
    setIsLoading(true);
    formData.legalEntityTypeId = legalEntities.find(
      (nt) => nt.name === formData.legalEntityTypeName
    )?.legalEntityTypeId;
  
    const location = formData.selectedLocation as GoogleLocation;
  
    const addressLine1 = location ? location.address : "";
    const addressLine2 = location ? `${location.coordinates.lat}, ${location.coordinates.lng}` : formData.physicalAddressLine2 || "";
    const city = location ? location.components.city : formData.physicalCity || "";
    const postalCode = location ? location.components.postalCode : formData.physicalCode || "";
  
    let response, title;
    let transformedData;
  
    try {
      if (selectedOrg) {
        const contactIdEmail = selectedOrg.contactDetail[0]?.contacts.find(contact => contact.type === "Email")?.contactId || null;
        const contactIdMobile = selectedOrg.contactDetail[0]?.contacts.find(contact => contact.type === "Mobile")?.contactId || null;
        const addressIdPhysical = selectedOrg.physicalAddress[0]?.addressId || 0;
  
        transformedData = {
          PartyId: selectedOrg.partyId,
          OrganizationId: selectedOrg.organizationId,
          Name: formData.orgName || "",
          VATNumber: formData.vatNumber || "",
          LegalEntityTypeId: formData.legalEntityTypeId || "",
          RegistrationNumber: formData.registrationNumber || "",
          ContactDetail: [
            {
              Name: formData.contactName || "",
              Surname: formData.contactSurname || "",
              Contacts: [
                { Type: "Email", Details: formData.contactEmail || "", ContactId: contactIdEmail },
                { Type: "Mobile", Details: formData.contactNumber || "", ContactId: contactIdMobile },
              ],
            },
          ],
        };
  
        if (addressLine1) {
          transformedData.PhysicalAddress = [
            {
              AddressId: addressIdPhysical,
              AddressLine1: addressLine1,
              AddressLine2: addressLine2,
              City: city,
              Code: postalCode,
            },
          ];
        }
  
        response = await updateOrganization(transformedData);
        title = "Organisation successfully updated";
        fetchData(getOrganizations, setOrganizations, setIsLoading);
        await refreshOrganizations();
      } else {
        transformedData = {
          Name: formData.orgName || "",
          VATNumber: formData.vatNumber || "",
          LegalEntityTypeId: formData.legalEntityTypeId || "",
          RegistrationNumber: formData.registrationNumber || "",
          ContactDetail: [
            {
              Name: formData.contactName || "",
              Surname: formData.contactSurname || "",
              Contacts: [
                { Type: "Email", Details: formData.contactEmail || "" },
                { Type: "Mobile", Details: formData.contactNumber || "" },
              ],
            },
          ],
        };
  
        if (addressLine1) {
          transformedData.PhysicalAddress = {
            AddressLine1: addressLine1,
            AddressLine2: addressLine2,
            City: city,
            Code: postalCode,
          };
        }
  
        response = await createOrganization(transformedData);
        title = "Organisation successfully created";
        fetchData(getOrganizations, setOrganizations, setIsLoading);
        await refreshOrganizations();
        setSelectedOrganization(organizations[organizations.length-1]);
      }
  
      handleCloseForm();
    } catch (error) {
      console.error("Error submitting organisation:", error);
    } finally {
      setIsLoading(false);
    }
  
    handleApiResponse(response, showSnackbar, { title: title });
  };  
  
  const handleConfirm = async () => {
    let response;

    if (selectedOrg) {
      setIsLoading(true);
      setConfirmOpen(false);
      try {
        response = await deleteOrganization(selectedOrg.partyId);

        if (response.status === 200) {
          const updatedOrganizations = await getOrganizations();
          setOrganizations(updatedOrganizations);
          await refreshOrganizations();

          if (updatedOrganizations.length === 0) {
            setSelectedOrganization(null);
            const newUrl = window.location.origin + window.location.pathname.split('/organisation')[0];
            window.location.replace(newUrl);
          } else if (selectedOrg.partyId === selectedOrganization?.partyId) {
            setSelectedOrganization(updatedOrganizations[0]);
          }
        }
      } catch (error) {
        console.error("Failed to delete organisation:", error);
      } finally {
        setIsLoading(false);
      }
    }
    handleCloseForm();
    handleApiResponse(response, showSnackbar, {
      title: "Organisation successfully deleted",
    });
  };


  const myColumns: ColumnConfig[] = [
    {
      label: "Organisation Name",
      dataKey: "name",
      renderCell: (item) => <>{item.name}</>,
    },
    {
      label: "Registration Number",
      dataKey: "registrationNumber",
      renderCell: (item) => <>{item.registrationNumber}</>,
    },
    {
      label: "VAT Number",
      dataKey: "VAT",
      renderCell: (item) => <>{item.vatNumber}</>,
    },
    {
      label: "Contact Information",
      dataKey: "contactDetail",
      renderCell: (item) => (
        <>
          {item.contactDetail[0].name + " " + item.contactDetail[0].surname}
          {item.contactDetail[0].contacts.map((contact: Contacts) => (
            <p key={contact.contactId}>
              <p>
                {contact.type}: {contact.details} 
              </p>
            </p>
          ))}
        </>
      ),
    },
    {
      label: "",
      dataKey: "action",
      renderCell: (item) => (
        <ActionButtons
          onEdit={() => handleEdit(item)}
          onDelete={() => handleDelete(item)}
        ></ActionButtons>
      ),
    },
  ];


//   return (
//     <Box sx={{ p: 2 }}>
//       <CustomSnackbar ref={snackbarRef} />
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <Box display="flex" alignItems="center" mb={2}>
//             <Typography variant="h6" sx={{ mr: 2 }}>
//               Organisations
//             </Typography>
//             <Button
//               variant="contained"
//               startIcon={<AddIcon />}
//               onClick={() => handleOpenForm()}
//               color="primary"
//             >
//               Add Organisation
//             </Button>
//           </Box>
//         </Grid>
//         <Grid item xs={12}>
//           {!isLoading && organizations.length > 0 && (
//             <>
//               <DynamicTable
//                 data={organizations}
//                 columns={myColumns}
//                 rowsPerPage={5}
//               />
//               <GenericConfirmDialog
//                 open={confirmOpen}
//                 onCancel={() => setConfirmOpen(false)}
//                 onConfirm={handleConfirm}
//                 title="Confirm Deletion"
//                 content="Are you sure you want to delete this organisation?"
//               />
//               <NotificationDialog
//                 open={notificationOpen}
//                 handleClose={() => setNotificationOpen(false)}
//                 title={notificationTitle}
//                 subtitle=""
//                 text={notificationMessage}
//               />
//             </>
//           )}
//         </Grid>
//       </Grid>
//       <OrganizationDialog
//         isOpen={formOpen}
//         onClose={() => handleCloseForm()}
//         onSubmit={handleSubmit}
//         formData={selectedOrg}
//         legalEntities={legalEntities}
//       />
//     </Box>
//   );
// };

  return (
    <>

      <CustomSnackbar ref={snackbarRef} />

      <Grid container sx={{ p: 2, pb: 0 }}>
        <Grid item xs={2.5}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Organisations
          </Typography>
        </Grid>   
        <Grid item xs={9.5}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenForm()}
            color="primary"
          >
            Add Organisation
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {!isLoading && organizations.length > 0 && (
          <>
            <DynamicTable
              data={organizations}
              columns={myColumns}
              rowsPerPage={5}
            />
            <GenericConfirmDialog
              open={confirmOpen}
              onCancel={() => setConfirmOpen(false)}
              onConfirm={handleConfirm}
              title="Confirm Deletion"
              content="Are you sure you want to delete this organisation?"
            />
            <NotificationDialog
              open={notificationOpen}
              handleClose={() => setNotificationOpen(false)}
              title={notificationTitle}
              subtitle=""
              text={notificationMessage}
            />
          </>
        )}
      </Grid>

      <OrganizationDialog
        isOpen={formOpen}
        onClose={() => handleCloseForm()}
        onSubmit={handleSubmit}
        formData={selectedOrg}
        legalEntities={legalEntities}
      />

    </>
  );
};

export default OrganizationSettings;