import React, { useState } from "react";
import {
  Avatar,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import barnIcon from "../../assets/icons/barnIcon.svg";
import { Organization } from "../../models/organization.interface";
import { useConfig } from "../../context/ConfigContextTest";

import { useNavigate } from "react-router-dom";

const UserOrganizationComponent = ({ open }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();

  const navigate = useNavigate()

  const { selectedOrganization, organizations, setSelectedOrganization, refreshMappedFields } = useConfig();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrgClick = (org: Organization) => {
    //console.log(org);
    setSelectedOrganization(org);
    refreshMappedFields();
    handleClose();
    navigate('/')
  };

  
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          padding: "10px",
          minWidth: "50px",
          paddingLeft: "8px",
          backgroundColor: theme.palette.background.paper,
          "&:hover": {
            backgroundColor: "#C5CBBC",
          },
        }}
        onClick={handleClick}
      >
        <Grid container>
          <Grid item xs={2} sx={{ marginTop: 1 }}>
            <img src={barnIcon} alt="barnIcon" />
          </Grid>
          {open && (
            <>
              <Grid item xs={9} sx={{ paddingLeft: 2 }}>
                <Typography
                  variant="body1"
                  sx={{
                    wordWrap: "normal",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {selectedOrganization ? selectedOrganization.name : "Name"}
                </Typography>
                <Typography variant="caption">Organisation</Typography>
              </Grid>
              <Grid item xs={1} sx={{ marginTop: 2 }}>
                <UnfoldMoreIcon fontSize="small" />
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          sx: {
            minWidth: "210px",
          },
        }}
      >
        {organizations.map((org) => (
          <MenuItem
            key={org.partyId}
            sx={{
              color: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: "#C5CBBC",
                color: theme.palette.primary.main,
              },
            }}
            onClick={() => handleOrgClick(org)}
          >
            <Avatar
              alt="Org"
              sx={{
                marginRight: "10px",
                height: "30px",
                width: "30px",
                fontSize: "15px",
                backgroundColor: theme.palette.primary.main,
              }}
              src="/path/to/avatar-image.jpg"
            >
              {org.name.charAt(0)}
            </Avatar>
            {org.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default UserOrganizationComponent;
