import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import DynamicFormDialog from "../molecules/dialog";
import { useDropzone } from "react-dropzone";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const MAX_FILE_SIZE = Number(process.env.REACT_APP_MAX_FILE_SIZE) || 10 * 1024 * 1024;

const baseSchema = yup.object().shape({
  fileName: yup.string().required("File Name is required"),
  docType: yup
    .number()
    .required("Document Type is required")
    .min(1, "Please select a Document Type"),
  file: yup.mixed(),
});

const getValidationSchema = (isEditing) => {
  return yup.object().shape({
    documents: yup.array()
      .min(1, "At least one document is required")
      .of(
        baseSchema.shape({
          file: isEditing
            ? yup.mixed().notRequired()
            : yup.mixed().required("File is required"),
        })
      )
      .required("Documents are required"),
  });
};


const DocumentDialog = ({
  isOpen,
  onClose,
  onSubmit,
  docTypes,
  formData,
  isEditing = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileErrors, setFileErrors] = useState<string[]>([]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      documents: [],
    },
    resolver: yupResolver(getValidationSchema(isEditing)),
    mode: "onChange",
  });

  const documents = watch("documents");
  const [isLoading, setIsLoading] = useState(false);
  
  const { fields, append, remove } = useFieldArray({
    control,
    name: "documents",
  });

  const sortDocuments = useCallback((docs) => {
    return [...docs].sort((a, b) => {
      const dateA = new Date(a.createdDate || 0);
      const dateB = new Date(b.createdDate || 0);
      return dateB.getTime() - dateA.getTime();
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.ms-excel": [".xls", ".xlsx", ".csv"],
      "application/msword": [".doc", ".docx"],
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/tiff": [".tif", ".tiff"],
      "image/svg+xml": [".svg"],
      "image/vnd.adobe.photoshop": [".psd"],
    },
    maxSize: MAX_FILE_SIZE,
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => ({
        fileName: file.name.replace(/\.[^/.]+$/, ""),
        docType: docTypes[0]?.value || 0,
        file,
        createdDate: new Date().toISOString(),
      }));
      if (isEditing) {
        setValue("documents", newFiles);
      } else {
        append(newFiles);
      }
      setFileErrors([]);
    },
    onDropRejected: (rejectedFiles) => {
      const errors = rejectedFiles.map(
        (rejectedFile) => `File ${rejectedFile.file.name} is too large. Max file size is 10MB.`
      );
      setFileErrors(errors);
    },
  });

  const handleDialogClose = useCallback(() => {
    reset({ documents: [] });
    setFileErrors([]);
    setIsSubmitting(false);
    onClose();
  }, [reset, onClose]);

  useEffect(() => {
    if (isOpen) {
      if (formData && isEditing) {
        const sortedDocs = sortDocuments(formData.documents);
        reset({ documents: sortedDocs });
      } else {
        reset({ documents: [] });
      }
      setFileErrors([]);
      setIsSubmitting(false);
    }
  }, [isOpen, formData, reset, isEditing, sortDocuments]);

  const validateDocuments = useCallback((data) => {
    if (!data.documents || data.documents.length === 0) {
      return "At least one document is required";
    }

    for (const doc of data.documents) {
      if (!isEditing && !doc.file) {
        return "File is required for all documents";
      }
      if (!doc.fileName) {
        return "File name is required for all documents";
      }
      if (!doc.docType || doc.docType < 1) {
        return "Valid document type is required for all documents";
      }
    }
    return null;
  }, [isEditing]);

  const handleFormSubmit = async (data) => {
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      setIsLoading(true);
      
      const validationError = validateDocuments(data);
      if (validationError) {
        setFileErrors([validationError]);
        return;
      }

      await getValidationSchema(isEditing).validate(data, { abortEarly: false });
      
      const documentsWithDates = data.documents.map(doc => ({
        ...doc,
        createdDate: doc.createdDate || new Date().toISOString()
      }));
      
      const sortedDocuments = sortDocuments(documentsWithDates);
      await onSubmit({ ...data, documents: sortedDocuments });
      handleDialogClose();
    } catch (error) {
      console.error("Validation Error:", error);
      if (error instanceof yup.ValidationError) {
        setFileErrors(error.errors);
      }
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };

  const formContent = (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <DialogContent dividers sx={{ pt: 1, pb: 1 }}>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          {fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <Grid item xs={!isEditing && fields.length > 1 ? 5 : 6}>
                <Controller
                  name={`documents.${index}.fileName`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="File Name"
                      fullWidth
                      size="small"
                      margin="dense"
                      error={!!errors?.documents?.[index]?.fileName}
                      helperText={errors?.documents?.[index]?.fileName?.message}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue(`documents.${index}.fileName`, e.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={!isEditing && fields.length > 1 ? 5 : 6}>
                <Controller
                  name={`documents.${index}.docType`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Document Type"
                      fullWidth
                      size="small"
                      margin="dense"
                      error={!!errors?.documents?.[index]?.docType}
                      helperText={errors?.documents?.[index]?.docType?.message}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue(
                          `documents.${index}.docType`,
                          parseInt(e.target.value)
                        );
                      }}
                    >
                      {docTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              {!isEditing && fields.length > 1 && (
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1 }}
                    onClick={() => remove(index)}
                  >
                    Remove
                  </Button>
                </Grid>
              )}
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed #cccccc",
                padding: "20px",
                pt: "10px",
                pb: "10px",
                textAlign: "center",
                cursor: "pointer",
                borderRadius: "5px",
              }}
            >
              <input {...getInputProps()} />
              <Typography>
                {isEditing
                  ? "Replace file: Drag 'n' drop a file here, or click to select a file"
                  : "Drag 'n' drop files here, or click to select files"}
              </Typography>
            </Box>
            {fileErrors.length > 0 && (
              <Typography color="error" sx={{ mt: 1 }}>
                {fileErrors.join(", ")}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<SaveIcon />}
          disabled={isLoading || isSubmitting}
        >
          {isEditing ? "Update" : "Save"}
          {isLoading && (
            <CircularProgress color="inherit" size={"15px"} sx={{ ml: 2 }} />
          )}
        </Button>
      </DialogActions>
    </form>
  );

  return (
    <DynamicFormDialog
      isOpen={isOpen}
      onClose={handleDialogClose}
      onSubmit={undefined}
      title={isEditing ? "Update Documents" : "Add Documents"}
      formContent={formContent}
    />
  );
};

export default DocumentDialog;