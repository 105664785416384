import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { usePopup } from '../../context/PopupContext';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    //border: `8px solid ${theme.palette.background.paper}`,
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: 'center',
  paddingBottom: theme.spacing(2),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: theme.spacing(1),
}));

const StyledListItem = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'center',
  padding: theme.spacing(3),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.background.paper,
  textDecoration: 'none',
  marginTop: theme.spacing(2),
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const FieldMappingPopup: React.FC = () => {
  const { showMappingPopup, setShowMappingPopup } = usePopup();

  const dialogContent = [
    "Make sure you create the fields according to their boundaries",
    "For a quick start guide click the link below:",
  ];

  return (
    <StyledDialog 
      open={showMappingPopup} 
      onClose={() => setShowMappingPopup(false)} 
      aria-labelledby="field-mapping-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <StyledDialogTitle id="field-mapping-dialog-title" variant="h5">
        Let's Create a Field!
      </StyledDialogTitle>
      <StyledDialogContent>
        <Box>
          {dialogContent.map((item, index) => (
            <StyledListItem key={index} variant="body1">
              {item}
            </StyledListItem>
          ))}
          <StyledLink 
            href="https://youtu.be/3wZYCM6i_MI?si=6-uMGTIIlttCGkWq" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            How to create a farm field
            <PlayCircleOutlineIcon sx={{ ml: 1 }} />
          </StyledLink>
        </Box>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton 
          onClick={() => setShowMappingPopup(false)} 
          variant="contained"
        >
          Got it
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default FieldMappingPopup;