import React from "react";
import { Controller } from "react-hook-form";
import { Button } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import {
  Grid,
  Typography,
  MenuItem,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Box,
  Select,
  OutlinedInput,
  Chip,
} from "@mui/material";
import ColoredRadio from "./ColoredRadio";
import DateSelector from "../atom/dateSelect";
import AddAttachmentButton from "../atom/attachmentButton";
import TextBox from "../atom/textBox";
import MapComponent from "../organisms/locationMap";
import DateRangePicker from "../atom/dateRange";
import AddIcon from "@mui/icons-material/Add";
import theme from "../../theme";
import MyMapComponent from "./googleMaps";
import SearchableSelect from "../atom/SearchableSelect";

interface Field {
  id: string;
  label: string;
  type?: string;
  value?: any;
  disabled?: boolean;
  min?: number;
  max?: number;
  defaultValue?: any;
  options?: Array<{ label: string; value: any; id: any; color?: string }>;
  placeholder?: string;
  readOnly?: boolean;
  apiEndpoint?: string;
  properties?: Field[];
}

interface DynamicFormSectionProps {
  title?: string;
  fields: Field[];
  control: any;
  errors: any;
  columns?: number;
  onFileChange?: (file: File | null) => void;
  onClick?: () => void;
  onPositionChange?: (position: { lat: number; lng: number }) => void;
  onLocationSelect?: any;
  initialLocation?: { lat: number; lng: number };
  initialAddress?: string;
  defaultValue?: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name: string, personName: readonly string[], theme: any) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const getLabelForValue = (
  value: any,
  options: Array<{ label: string; value: any }>
) => {
  const option = options.find((option) => option.value === value);
  return option ? option.label : value;
};

const DynamicFormSection: React.FC<DynamicFormSectionProps> = ({
  title = "",
  fields,
  control,
  errors,
  columns = 1,
  onFileChange = () => {},
  onClick,
  onPositionChange = () => {},
  initialLocation,
  initialAddress,
  defaultValue,
}) => {
  const gridColumnWidth = Math.floor(12 / columns);
  const Circle = ({ color }) => (
    <Box
      style={{
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: color,
        marginLeft: "auto",
      }}
    />
  );

  return (
    <>
      <Grid container spacing={0.5}>
        {title && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" color={"black"}>
              {title}
            </Typography>
          </Grid>
        )}
        {fields?.map((field) => (
          <Grid item xs={12} sm={gridColumnWidth} key={field.id}>
            <Controller
              name={field.id}
              control={control}
              defaultValue={field.defaultValue || defaultValue || []}
              render={({ field: { onChange, onBlur, value = [], ref } }) => {
                switch (field.type) {
                  case "select":
                    return (
                      <TextBox
                        select
                        id={field.id}
                        label={field.label}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors[field.id]}
                        helperText={errors[field.id]?.message}
                        inputRef={ref}
                        disabled={field.disabled}
                        sx={{ mb: 1 }}
                      >
                        {Array.isArray(field.options) ? (
                          field.options
                            .filter((option) => option.value !== "")
                            .map((option) => (
                              <MenuItem key={option.id} value={option.value}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  {option.label}
                                  {option.color && (
                                    <Circle color={option.color} />
                                  )}
                                </div>
                              </MenuItem>
                            ))
                        ) : (
                          <MenuItem disabled>No Options Available</MenuItem>
                        )}
                      </TextBox>
                    );
                  case "selectAdd":
                    return (
                      <Grid container spacing={1}>
                        <Grid item xs={10.5}>
                          <TextBox
                            select
                            id={field.id}
                            label={field.label}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={!!errors[field.id]}
                            helperText={errors[field.id]?.message}
                            inputRef={ref}
                            sx={{ mb: 1 }}
                          >
                            {Array.isArray(field.options) ? (
                              field.options.map((option) => (
                                <MenuItem key={option.id} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>No Options Available</MenuItem>
                            )}
                          </TextBox>
                        </Grid>
                        <Grid item xs={1.5}>
                          <Tooltip title="New Note">
                            <IconButton
                              aria-label="add"
                              sx={{
                                color: "white",
                                backgroundColor: theme.palette.secondary.main,
                                width: "30px",
                                height: "30px",
                                margin: "10px",
                                "&:hover": {
                                  backgroundColor:
                                    theme.palette.secondary.light,
                                },
                              }}
                              onClick={onClick}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    );
                  case "radioGroup":
                    return (
                      <ColoredRadio
                        id={field.id}
                        value={value}
                        onChange={onChange}
                        error={!!errors[field.id]}
                        helperText={errors[field.id]?.message}
                      />
                    );
                  case "attachment":
                    return (
                      <AddAttachmentButton
                        id={field.id}
                        label={field.label}
                        onChange={onFileChange}
                        onBlur={onBlur}
                        error={!!errors[field.id]}
                        helperText={errors[field.id]?.message}
                      />
                    );
                  case "label":
                    return (
                      <TextBox
                        id={field.id}
                        label={field.label}
                        value={field.value}
                        onChange={() => {}}
                        readOnly
                        sx={{ mb: 1 }}
                      />
                    );
                  case "map":
                    return (
                      <MapComponent
                        id={field.id}
                        label={field.label}
                        error={!!errors.location}
                        helperText={errors.location?.message}
                        onPositionChange={onPositionChange}
                      />
                    );
                  case "multiText":
                    return (
                      <TextBox
                        id={field.id}
                        label={field.label}
                        placeholder={field.placeholder}
                        type={field.type}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors[field.id]}
                        helperText={errors[field.id]?.message}
                        multiline
                        rows={2}
                        sx={{ mb: 1 }}
                      />
                    );
                  case "date":
                    return (
                      <DateSelector
                        id={field.id}
                        label={field.label}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors[field.id]}
                        helperText={errors[field.id]?.message}
                      />
                    );
                  case "googleMapsSearch":
                    return (
                      <MyMapComponent
                        initialLocation={initialLocation}
                        initialAddress={initialAddress}
                      />
                    );
                  case "dateRange":
                    return <DateRangePicker />;
                  case "multiSelectChip":
                    return (
                      <FormControl
                        fullWidth
                        margin="dense"
                        error={!!errors[field.id]}
                        sx={{ mb: 1 }}
                      >
                        <InputLabel id={`multiple-chip-label-${field.id}`}>
                          {field.label}
                        </InputLabel>
                        <Select
                          labelId={`multiple-chip-label-${field.id}`}
                          id={`multiple-chip-${field.id}`}
                          multiple
                          value={value}
                          onChange={onChange}
                          input={
                            <OutlinedInput
                              id={`select-multiple-chip-${field.id}`}
                              label={field.label}
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((val: any) => (
                                <Chip
                                  key={val}
                                  label={getLabelForValue(
                                    val,
                                    field.options || []
                                  )}
                                />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {Array.isArray(field.options) ? (
                            field.options.map((option) => (
                              <MenuItem
                                key={option.id}
                                value={option.value}
                                style={getStyles(option.label, value, theme)}
                              >
                                {option.label}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>No Options Available</MenuItem>
                          )}
                        </Select>
                        {errors[field.id] && (
                          <Typography variant="body2" color="error">
                            {errors[field.id]?.message}
                          </Typography>
                        )}
                      </FormControl>
                    );
                  case "searchableSelect":
                    return (
                      <SearchableSelect
                        id={field.id}
                        label={field.label}
                        value={value}
                        onChange={onChange}
                        options={field.options || []}
                        error={!!errors[field.id]}
                        helperText={errors[field.id]?.message}
                        inputRef={ref}
                      />
                    );
                  case "ChipList":
                    return (
                      <FormControl
                        fullWidth
                        margin="dense"
                        error={!!errors[field.id]}
                        sx={{ mb: 1 }}
                      >
                        <Typography variant="subtitle1">
                          {field.label}
                        </Typography>
                        {Array.isArray(field.options) &&
                        field.options.length > 0 ? (
                          <Grid container spacing={1}>
                            {field.options.map((option) => (
                              <Grid item key={option.id}>
                                <Chip label={option.label} variant="outlined" />
                              </Grid>
                            ))}
                          </Grid>
                        ) : (
                          <>
                            <Typography variant="caption">
                              No Options Available
                            </Typography>
                          </>
                        )}
                      </FormControl>
                    );
                  case "button":
                    return (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<NoteAddIcon />}
                        onClick={onClick}
                        fullWidth
                        id={field.id}
                        sx={{ mt: 1 }}
                      >
                        {field.label}
                      </Button>
                    );
                  case "currency":
                    return (
                      <TextBox
                        id={field.id}
                        label={field.label}
                        type="currency"
                        value={field.value || value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors[field.id]}
                        helperText={errors[field.id]?.message}
                        inputRef={ref}
                        readOnly={field.readOnly}
                        sx={{ mb: 1 }}
                      />
                    );
                  default:
                    return (
                      <TextBox
                        id={field.id}
                        label={field.label}
                        placeholder={field.placeholder}
                        type={field.type}
                        value={value}
                        min={field.min}
                        max={field.max}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors[field.id]}
                        disabled={field.disabled}
                        helperText={errors[field.id]?.message}
                        readOnly={field.readOnly}
                        sx={{ mb: 0 }}
                      />
                    );
                }
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DynamicFormSection;
