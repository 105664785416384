import React, { useState, useEffect } from "react";
import "./bootstrap/css/bootstrap.min.css";
import "./css/blocks.css";
import "./css/style.css";
import hero from "./img/heroBackground.png";
import wheat1 from "./img/wheat1.jpg";
import sunflower from "./img/sunflower.jpg";
import bigSunflower from "./img/bigSunflower.png";
import ffLogoWhite from "./img/logos/FarmersFriend_portrait_white.png";
import aboutImg from "./img/About200h.png";
import solutionImage from "./img/solutionImage.png";
import gisImg from "./img/gis.png";
import labourImg from "./img/labour.png";
import offlineImg from "./img/offline.png";
import oversightImg from "./img/oversight.png";
import budgetingImg from "./img/budgeting.png";
import impacttrackingImg from "./img/impacttracking.png";
import enterpriseImage from "./img/enterpriseImage.png";
import commodityPriceImg from "./img/COMMODITY-PRICES.png";
import soilMoistureImg from "./img/SOIL-MOISTURE.png";
import fieldHealthImg from "./img/FIELD-HEALTH.png";
import yieldEstimateImg from "./img/YIELD-ESTIMATE.png";
import prodCostImg from "./img/PRODUCTION-COST.png";
import seasonTrackImg from "./img/SEASON-TRACKING.png";
import agnifyLogo from "./img/agnifyLogo200.png";
import ffWhite from "./img/FarmersFriend_white200.png";

interface FFPageProps {
  onSignUpClick: () => void;
}

const FFPage: React.FC<FFPageProps> = ({ onSignUpClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "https://desk.zoho.com/portal/api/feedbackwidget/1032093000000287377?orgId=868543034&displayType=popout";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <div className="bg-primary text-secondary" id="home">
      <section
        className="background-cover image-fit img-fluid"
        style={{ backgroundImage: `url(${hero})` }}
      >
        <nav className="bg-transparent navbar navbar-expand-md">
          <div className="container-fluid d-flex flex-row justify-content-around px-2">
            <a className="navbar-brand" href="index.html">
              <img
                src={ffLogoWhite}
                className="img-fluid ps-2"
                width="120"
                alt="Farmers Friend"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown-3"
              aria-controls="navbarNavDropdown-3"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown-3">
              <ul className="ms-auto navbar-nav navbar-dark my-2 mx-2">
                <li className="border-1 border-end border-primary nav-item">
                  <a className="nav-link px-md-3" href="#aboutSection">
                    About
                  </a>
                </li>
                <li className="border-1 border-end border-primary nav-item dropdown">
                  <a
                    className="nav-link px-md-3 dropdown-toggle"
                    href="none"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleDropdown();
                    }}
                  >
                    Solutions
                  </a>
                  <div className={`dropdown-menu ${isOpen ? "show" : ""}`}>
                    <a className="dropdown-item" href="#solutionSection">
                      The Farmers Solution
                    </a>
                    <a className="dropdown-item" href="#enterpriseSection">
                      Enterprise user
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <a href="#FFTechFooter" className="nav-link px-md-3">
                    Contact
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    href="https://agnify.atlassian.net/servicedesk/customer/portal/4"
                    className="nav-link px-md-3"
                  >
                    Support
                  </a>
                </li> */}
              </ul>
            </div>
            <div>
              <button
                className="btn btn-secondary me-2"
                onClick={onSignUpClick}
              >
                Sign up
              </button>
              <button className="btn btn-accent" onClick={onSignUpClick}>
                Sign in
              </button>
            </div>
          </div>
        </nav>
        <div id="heroText" className="text-center">
          <h1 className="border-3 border-bottom border-secondary d-inline-block fw-normal text-light my-5">
            Your ultimate
            <br />
            <span className="fst-italic">farming </span>
            <span className="fw-bold">companion</span>
          </h1>
        </div>
      </section>

      {/* Body */}
      <section
        id="aboutSection"
        className="align-items-center d-flex flex-md-nowrap flex-row flex-wrap-reverse justify-content-center p-2 p-md-5"
      >
        <div className="pe-md-4">
          <img src={aboutImg} alt="About Us" />
        </div>
        <div>
          <h1 className="border-3 border-bottom border-secondary d-inline-block fw-light text-light mb-3">
            About Us
          </h1>
          <p className="text-light">
            Farmers Friend is a homegrown farming application and a testament to
            our commitment to technological innovation and the future of farming
            in Africa. Our user-friendly mobile and desktop app enables farmers
            to seamlessly log their field activities, while the Farmers Friend
            Enterprise platform offers a tailored solution for financiers,
            offering a comprehensive view of fund utilisation, farming
            practices, and projected outcomes. At Agnify, we’re dedicated to
            transforming farming through technology and fostering transparency,
            efficiency, and growth across the agricultural landscape.
          </p>
        </div>
      </section>

      <section
        id="solutionSection"
        className="align-items-center background-cover bg-primary d-flex flex-column"
        style={{ backgroundImage: `url(${wheat1})` }}
      >
        <div className="align-items-center p-2 p-md-5 bg-opacity-90 bg-primary d-flex flex-row flex-md-nowrap flex-wrap justify-content-center">
          <div>
            <h1 className="border-3 border-bottom border-secondary d-inline-block fw-light text-light mb-3">
              The Farmers Solution
            </h1>
            <p className="text-light">
              We are thrilled to present Farmers Friend, a cutting-edge
              web-based application designed to revolutionize your farming
              experience. Whether you're a seasoned farmer or just starting,
              Farmers Friend is the ultimate tool to manage and optimize your
              agricultural activities like never before.
            </p>
          </div>
          <div className="ps-2 ps-md-4">
            <img
              src={solutionImage}
              style={{
                display: "inline-block",
                maxWidth: "300px",
                width: "auto",
              }}
              alt="Solution"
            />
          </div>
        </div>
        <div className="align-self-stretch bg-opacity-90 bg-primary d-flex flex-row flex-wrap justify-content-around p-2 p-md-5">
          <div id="check1" className="text-center p-1">
            <img src={gisImg} width="70px" alt="GIS farm mapping" />
            <p className="fw-semibold py-3 fs-sm">GIS farm mapping</p>
          </div>
          <div id="check2" className="text-center p-1">
            <img src={labourImg} width="70px" alt="Labour & land preparation" />
            <p className="fw-semibold py-3 fs-sm">
              Labour & land preparation etc.
            </p>
          </div>
          <div id="check3" className="text-center p-1">
            <img
              src={offlineImg}
              width="70px"
              alt="Offline & online capabilities"
            />
            <p className="fw-semibold py-3 fs-sm">
              Offline & online capabilities
            </p>
          </div>
          <div id="check4" className="text-center p-1">
            <img
              src={oversightImg}
              width="70px"
              alt="Oversight of field analytics"
            />
            <p className="fw-semibold py-3 fs-sm">
              Oversight of field analytics
            </p>
          </div>
          <div id="check5" className="text-center p-1">
            <img src={budgetingImg} width="70px" alt="Season budgeting" />
            <p className="fw-semibold py-3 fs-sm">Season budgeting</p>
          </div>
          <div id="check6" className="text-center p-1">
            <img
              src={impacttrackingImg}
              width="70px"
              alt="Input tracking for fertilisers & chemicals"
            />
            <p className="fw-semibold py-3 fs-sm">
              Input tracking for fertilisers & chemicals
            </p>
          </div>
        </div>
        {/* <div className="bg-opacity-90 bg-primary container-fluid d-flex flex-row justify-content-center p-2 p-md-5 text-center">
          <a
            className="btn btn-secondary border-primary ms-3"
            href="https://app.iqthentic.com/onboarding/"
          >
            <b>R175 </b>per month
            <span className="small fst-italic"> annual payment</span>
          </a>
        </div> */}
      </section>

      <section
        id="sunflowerSection"
        className="background-cover img-fluid p-5"
        style={{ backgroundImage: `url(${sunflower})` }}
      >
        <div className="border-3 border-end border-secondary">
          <h1 className="fw-light text-end text-light pe-3">
            For your
            <br />
            <span className="fw-bold">best season,</span>
            <br />
            <i>every season</i>
          </h1>
        </div>
      </section>

      <section
        id="enterpriseSection"
        className="background-cover bg-primary bg-opacity-90"
        style={{ backgroundImage: `url(${bigSunflower})` }}
      >
        <div
          id="enterpriseGrid"
          className="bg-primary p-2 p-md-5 bg-opacity-90"
        >
          <div className="align-items-center d-flex flex-md-nowrap flex-row flex-wrap justify-content-center align-items-center">
            <div>
              <h1 className="border-3 border-bottom border-secondary d-inline-block fw-light text-light mb-3">
                For the Enterprise user
              </h1>
              <p className="text-light lh-base">
                Farmers Friend Enterprise is a transformative solution that
                bridges the gap between financing and farm activities. By
                leveraging the Farmers Friend mobile and desktop app, farmers
                efficiently log their field operations, which seamlessly
                integrate into Farmers Friend Enterprise. This consolidated
                platform empowers financiers to gain insights into fund
                utilization. With Farmers Friend Enterprise, informed financial
                decisions can be made, fostering transparency, accountability,
                and optimized agricultural investments for a more prosperous
                farming ecosystem.
              </p>
            </div>
            <div className="ps-md-4">
              <img
                src={enterpriseImage}
                style={{
                  display: "inline-block",
                  maxWidth: "300px",
                  width: "auto",
                }}
                alt="Enterprise"
              />
            </div>
          </div>
          <div className="d-flex flex-row flex-wrap justify-content-around">
            <div id="check1" className="text-center p-1">
              <img
                src={commodityPriceImg}
                width="70px"
                alt="Commodity prices"
              />
              <p className="fw-semibold py-3 fs-sm">Commodity prices</p>
            </div>
            <div id="check2" className="text-center p-1">
              <img src={soilMoistureImg} width="70px" alt="Soil moisture" />
              <p className="fw-semibold py-3 fs-sm">Soil moisture</p>
            </div>
            <div id="check3" className="text-center p-1">
              <img src={fieldHealthImg} width="70px" alt="Field Health" />
              <p className="fw-semibold py-3 fs-sm">Field Health</p>
            </div>
            <div id="check4" className="text-center p-1">
              <img src={yieldEstimateImg} width="70px" alt="Yield estimates" />
              <p className="fw-semibold py-3 fs-sm">Yeild estimates</p>
            </div>
            <div id="check5" className="text-center p-1">
              <img src={prodCostImg} width="70px" alt="Production cost" />
              <p className="fw-semibold py-3 fs-sm">Production cost</p>
            </div>
            <div id="check6" className="text-center p-1">
              <img src={seasonTrackImg} width="70px" alt="Season tracking" />
              <p className="fw-semibold py-3 fs-sm">Season tracking</p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-accent2 text-light" id="FFTechFooter">
        <div className="container-fluid">
          <div id="footerTopRow" className="row p-4">
            <div
              id="getInTouch"
              className="border border-1 d-flex flex-row flex-wrap justify-content-between rounded-3"
            >
              <div className="d-flex flex-column p-2 fs-sm">
                <h5 className="fw-semibold mb-1">GET IN TOUCH</h5>
                <a
                  href="mailto: support@iqlogistica.com"
                  className="link-light text-decoration-none"
                >
                  <b className="fw-semibold">Email:</b> info@agnify.io
                </a>
              </div>
              <div className="p-2 fs-sm">
                <h5 className="fw-semibold mb-1">EXPLORE</h5>
                <ul className="list-unstyled">
                  <li className="mb-1">
                    <a
                      href="#sectionAbout"
                      className="link-light text-decoration-none"
                    >
                      About Farmers Friend
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      href="https://agnify.io"
                      className="link-light text-decoration-none"
                    >
                      About Agnify
                    </a>
                  </li>
                </ul>
              </div>
              <div className="p-2 fs-sm">
                <h5 className="fw-semibold mb-1">COMPLIANCE</h5>
                <ul className="list-unstyled">
                  <li className="mb-1">
                    <a
                      href="https://stfarmersfrienddev.blob.core.windows.net/b2c-documents/FF_Terms_and_Conditions_20240730_v1.pdf"
                      className="link-light text-decoration-none"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PoPIA, PAIA, Privacy Policy
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      href="https://stfarmersfrienddev.blob.core.windows.net/b2c-documents/FF_Terms_and_Conditions_20240730_v1.pdf"
                      className="link-light text-decoration-none"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      External Grievance Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              id="logosSocial"
              className="align-items-center d-flex flex-row flex-wrap justify-content-between mt-2"
            >
              <div className="align-items-center d-flex flex-column mt-3">
                <a href="https://agnify.io">
                  <img src={agnifyLogo} className="img-fluid" alt="" />
                </a>
                <p className="d-sm-flex d-none fs-sm fst-italic text-center">
                  Farmers Friend is a proud member
                  <br />
                  of the Agnify brand of products
                </p>
              </div>
              <div className="mt-3">
                <img src={ffWhite} className="img-fluid" alt=""/>
              </div>
              <div className="mt-3">
                <div className="d-flex flex-row flex-md-nowrap flex-wrap justify-content-center text-secondary">
                  <a
                    href="https://web.facebook.com/FarmersFriendSA"
                    className="link-secondary py-1"
                    aria-label="facebook link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="50"
                      height="50"
                    >
                      <path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z" />
                    </svg>
                  </a>
                  <a
                    href="https://www.instagram.com/farmersfriendapp"
                    className="link-secondary p-1"
                    aria-label="instagram link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="50"
                      height="50"
                    >
                      <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
                    </svg>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/farmers-friend/"
                    className="link-secondary p-1"
                    aria-label="linkedin link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="50"
                      height="50"
                    >
                      <path d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="border-2 border-secondary border-top small">
            <div className="align-items-center row py-1">
              <div className="col">
                <p>
                  &copy; 1996 - 2024 | All Rights Reserved - Agnify (Pty) Ltd
                </p>
              </div>
              <div className="col-auto">
                <a href="none" className="link-light text-decoration-none">
                  &nbsp;
                </a>
                |
                <a
                  href="https://stfarmersfrienddev.blob.core.windows.net/b2c-documents/FF_Terms_and_Conditions_20240730_v1.pdf"
                  className="link-light text-decoration-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </div>
  );
};

export default FFPage;
