import React, { useState, useEffect, useCallback  } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getForecastWeather } from "../../services/api-ws-service";
import WindIcon from "../../assets/icons/weather/svg/wind_icon.svg"; 
import RainfallIcon from "../../assets/icons/weather/svg/rainfall.svg"; 

const importIcon = (symbolCode: string) => {
  try {
    return require(`../../assets/icons/weather/svg/${symbolCode}.svg`);
  } catch (error) {
    console.error(`Icon for symbol code ${symbolCode} not found.`);
    return null;
  }
};

interface WeatherForecastDialogProps {
  lat: any;
  long: any;
}

const WeatherForecastDialog: React.FC<WeatherForecastDialogProps> = ({ 
  lat, 
  long 
}) => {
  const [weatherData, setWeatherData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [currentWeather, setCurrentWeather] = useState<string>("Loading...");

  const [weatherIcon, setWeatherIcon] = useState<string>("");

  const fetchWeatherData = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getForecastWeather(lat, long);
      const timeseries = data?.properties?.timeseries || [];
      const now = new Date();
      const next24HoursData = timeseries.filter((item: any) => {
        const itemDate = new Date(item.time);
        return itemDate <= new Date(now.getTime() + 24 * 60 * 60 * 1000);
      });
  
      setWeatherData(next24HoursData);
  
      if (next24HoursData.length > 0) {
        const latestWeather = next24HoursData[0].data.instant.details.air_temperature;
        const latestPrecipitation = next24HoursData[0].data.next_1_hours.details.precipitation_amount;
        const symbolCode = next24HoursData[0].data.next_1_hours.summary.symbol_code;
        const currentIcon = importIcon(symbolCode);
        setCurrentWeather(`${latestWeather}\n${latestPrecipitation} mm`);
        setWeatherIcon(currentIcon); 
      } else {
        setCurrentWeather("No data");
        setWeatherIcon("");
      }
  
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch weather data:", error);
      setCurrentWeather("Error");
      setWeatherIcon("");
      setIsLoading(false);
    }
  }, [lat, long]); // Dependencies
  
  useEffect(() => {
    if (lat !== null && long !== null) {
      fetchWeatherData();
    }
  }, [lat, long, fetchWeatherData]);

  const handleButtonClick = () => {
    if (!isLoading && weatherData.length > 0) {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };


  const degreesToRotation = (degrees: number): string => {
    const normalizedDegrees = (degrees + 360) % 360;
    return `rotate(${normalizedDegrees}deg)`;
  };
  
  //const times = weatherData.map((item: any) => item.time.split("T")[1].split(":00")[0] + ":00");
  
  function getTimeWithTimezoneOffset(dateTimeUTC: string) {
    //const now = new Date();
    //const timeZoneOffset = now.getTimezoneOffset();
    let timeItem = new Date(dateTimeUTC);
    let timeDisplay = new Date(timeItem.getTime());
    let hours = String(timeDisplay.getHours());
    let mins = String(timeDisplay.getMinutes());
    return hours.padStart(2, "0")+":"+mins.padStart(2, "0");
  }

  const times = weatherData.map((item: any) => getTimeWithTimezoneOffset(item.time));
  

  return (
    <>
      <Button 
        onClick={handleButtonClick} 
        disabled={isLoading}
        title="Click to show weather forecast."
        sx={{
          backgroundColor: "rgba(255, 255, 255, 1)",
          color: "#333",
          padding: "4px 16px 4px 6px",
          fontSize: "16px",
          borderRadius: "8px",
          //boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(10px)",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "rgba(240, 240, 240, 1);",
          },
        }}
        >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: "40px", padding: "4px 0 0 4px", borderBottom: "none", textAlign: "center" }}>              
                    {weatherIcon && (
                      <img src={weatherIcon} alt="Weather icon" style={{ width: "40px", height: "40px" }} />
                    )}
                  </TableCell>
                  <TableCell style={{ width: "60px", padding: "4px 4px 0 0", fontSize: 16, textAlign: "right", borderBottom: "none" }}>
                    {Math.round(parseFloat(currentWeather.split('\n')[0]))} °C
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: "40px", padding: "0 0 4px 0", borderBottom: "none", textAlign: "center"}}>
                    {RainfallIcon && (
                      <img 
                      src={RainfallIcon} 
                      alt="Umbrella icon"
                      style={{ width: "26px", height: "30px", marginLeft: "10px" }} 
                      />
                    )}
                  </TableCell>
                  <TableCell sx={{ width: "60px", padding: "0 4px 4px 0", fontSize: 14, textAlign: "right", borderBottom: "none" }}>
                    {currentWeather.split('\n')[1]}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>      
          </>
        )}
        </Button>

      <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Weather Forecast</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        
        <div  style={{ padding: "0 24px 32px 24px"}}>
        <DialogContent style={{ padding: 0 }}>
          {isLoading ? (
            <CircularProgress />
          ) : (

          <Grid container>
            <Grid item xs={12} >
      
            <Table sx={{ textAlign: "center" }}>
              <TableHead>
                <TableRow>
                  <TableCell 
                    style={{ 
                      minWidth: 180, 
                      borderTop: "1px solid rgba(224, 224, 224, 1)" ,
                      position: 'sticky', 
                      left: 0, 
                      zIndex: 1, 
                      backgroundColor: 'white' 
                    }}>
                      &nbsp;
                  </TableCell>
                  {times.map((time, index) => (
                    <TableCell key={index} style={{ textAlign: "center", borderTop: "1px solid rgba(224, 224, 224, 1)" }}>{time}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                <TableCell
                  style={{ 
                    position: 'sticky', 
                    left: 0, 
                    zIndex: 1, 
                    backgroundColor: 'white' 
                  }}>
                    &nbsp;
                </TableCell>
                  {weatherData.map((hourData: any, index: number) => {
                    const symbolCode = hourData.data.next_1_hours.summary.symbol_code;
                    const Icon = importIcon(symbolCode);
                    return (
                      <TableCell key={index} sx={{ minWidth: 100, textAlign: "center" }}>
                        {Icon ? <img src={Icon} alt={symbolCode} style={{ width: "40px", height: "40px" }} /> : "No icon"}
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                <TableCell
                  style={{ 
                    position: 'sticky', 
                    left: 0, 
                    zIndex: 1, 
                    backgroundColor: 'white' 
                  }}>
                    Temperature, °C
                </TableCell>
                {weatherData.map((hourData: any, index: number) => (
                  <TableCell key={index} style={{ minWidth: 100, fontSize: 16, textAlign: "center" }}>{Math.round(parseFloat(hourData.data.instant.details.air_temperature))}</TableCell>
                ))}
                </TableRow>
                <TableRow>
                <TableCell
                  style={{ 
                    position: 'sticky', 
                    left: 0, 
                    zIndex: 1, 
                    backgroundColor: 'white' 
                  }}>
                    Rainfall, mm
                </TableCell>
                  {weatherData.map((hourData: any, index: number) => (
                    <TableCell key={index} style={{ textAlign: "center" }}>{hourData.data.next_1_hours.details.precipitation_amount}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                <TableCell
                  style={{ 
                    position: 'sticky', 
                    left: 0, 
                    zIndex: 1, 
                    backgroundColor: 'white' 
                  }}>
                    Air Pressure, hPa
                </TableCell>
                  {weatherData.map((hourData: any, index: number) => (
                    <TableCell key={index} style={{ textAlign: "center" }}>{Math.round(parseFloat(hourData.data.instant.details.air_pressure_at_sea_level))}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                <TableCell
                  style={{ 
                    position: 'sticky', 
                    left: 0, 
                    zIndex: 1, 
                    backgroundColor: 'white' 
                  }}>
                    Relative Humidity, %
                </TableCell>
                  {weatherData.map((hourData: any, index: number) => (
                    <TableCell key={index} style={{ textAlign: "center" }}>{Math.round(parseFloat(hourData.data.instant.details.relative_humidity))}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                <TableCell
                  style={{ 
                    position: 'sticky', 
                    left: 0, 
                    zIndex: 1, 
                    backgroundColor: 'white' 
                  }}>
                    Wind Speed, m/s
                </TableCell>
                  {weatherData.map((hourData: any, index: number) => {
                    const windSpeed = hourData.data.instant.details.wind_speed;
                    //const windDirection = degreesToCompass(hourData.data.instant.details.wind_from_direction);
                    return (
                      <TableCell key={index} style={{ textAlign: "center" }}>
                        {`${windSpeed}`}
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                <TableCell
                  style={{ 
                    position: 'sticky', 
                    left: 0, 
                    zIndex: 1, 
                    backgroundColor: 'white' 
                  }}>
                    Wind Direction
                </TableCell>
                  {weatherData.map((hourData: any, index: number) => {
                    const windDirection = hourData.data.instant.details.wind_from_direction;
                    const rotation = degreesToRotation(windDirection);
                    return (
                      <TableCell key={index} style={{ textAlign: "center" }}>
                        <img 
                          src={WindIcon} 
                          alt="Wind direction" 
                          style={{ width: '30px', height: '30px', transform: rotation }} 
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
            </Grid>
            </Grid>
          )}

        </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default WeatherForecastDialog;