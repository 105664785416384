import axios from "axios";
import { ResponseApi } from "../models/ResponseApi.interface";
import { 
    EnterpriseConnectResponse, 
    EnterpriseDetail, 
    EnterpriseConnection, 
    GetConnectionsResponse, 
    RejectConnectionRequest,
} from "../models/enterpriseConnect.interface";

const api = axios.create({
  baseURL: process.env.REACT_APP_FFM_BASE_URL + "/api/Connect/v1/",
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_FFM_API_KEY,
  },
});


export const getActiveEnterprises = async (): Promise<EnterpriseDetail[]> => {
    try {
        const response = await api.get<EnterpriseConnectResponse>("ActiveEnterprises");

        if (response.data.statusCode !== 200 || response.data.message !== "SUCCESS") {
        throw new Error(`API call unsuccessful: ${response.data.message}`);
        }
        return response.data.details;
    } catch (error: any) {
        if (error.response && error.response.data) {
        throw new Error(
            `Failed to retrieve active enterprises: ${
            error.response.data.message || error.message
            }`
        );
        } else {
        throw new Error("Something went wrong while retrieving active enterprises");
        }
    }
};

export const getConnections = async (azureUserId: string, partyId: number): Promise<EnterpriseConnection[]> => {
    try {
        const headers = {
            "x-PartyId": partyId,
            "x-AzureUserId": azureUserId
        };
        
        const response = await api.get<GetConnectionsResponse>("ManageFarmConnections", { headers });
      
        if (response.data.statusCode !== 200 || response.data.message !== "SUCCESS") {
            throw new Error(`API call unsuccessful: ${response.data.message}`);
        }
        return response.data.details;
    } catch (error: any) {
        if (error.response && error.response.data) {
            throw new Error(
                `Failed to retrieve connections: ${
                    error.response.data.message || error.message
                }`
            );
        } else {
            throw new Error("Something went wrong while retrieving connections");
        }
    }
};


export const initiateEnterpriseRequest = async (
    request: {
        AzureUserId: string,
        FFPPartyId: number,
        Enterprises: number[]
    },
    azureUserId: string,
): Promise<ResponseApi<any>> => {
    try {
        const headers = {
            "x-AzureUserId": azureUserId
        };

        const response = await api.post<ResponseApi<any>>(
            "FarmerInitiateRequest",
            request,
            { headers }
        );
        
        return response.data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            throw new Error(JSON.stringify(error.response.data));
        } else {
            throw new Error("Something went wrong while initiating the request");
        }
    }
};

export const acceptEnterpriseConnection = async (
    request: {
        EnterpriseFarmConnectId: number,
        AzureUserId: string
    },
    azureUserId: string,
    partyId: number
): Promise<ResponseApi<any>> => {
    try {
        const headers = {
            "x-PartyId": partyId,
            "x-AzureUserId": azureUserId
        };

        const response = await api.post<ResponseApi<any>>(
            "AcceptEnterpriseConnection",
            request,
            { headers }
        );
        
        return response.data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            throw new Error(JSON.stringify(error.response.data));
        } else {
            throw new Error("Something went wrong while accepting the connection");
        }
    }
};

export const rejectEnterpriseConnection = async (
    request: RejectConnectionRequest
): Promise<ResponseApi<any>> => {
    try {
        const response = await api.delete<ResponseApi<any>>(
            "RejectEnterpriseConnection",
            {
                data: request
            }
        );
        return response.data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            throw new Error(JSON.stringify(error.response.data));
        } else {
            throw new Error("Something went wrong while rejecting the connection");
        }
    }
};

export const terminateEnterpriseConnection = async (
    request: {
        EnterpriseFarmConnectId: number,
        AzureUserId: string
    }
): Promise<ResponseApi<any>> => {
    try {
        const response = await api.delete<ResponseApi<any>>(
            "TerminateEnterpriseConnection",
            {
                data: request, 
            }
        );
        
        return response.data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            throw new Error(JSON.stringify(error.response.data));
        } else {
            throw new Error("Something went wrong while terminating the connection");
        }
    }
};