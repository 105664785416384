import React, { useEffect, useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  // Autocomplete,
  // TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DynamicFormDialog from "../molecules/dialog";
import TextBox from "../atom/textBox";
import { z } from "zod";

const productSchema = z.object({
  Product: z.union([z.string(), z.number()]).refine((value) => {
    const parsedValue = typeof value === "string" ? parseFloat(value) : value;
    return !isNaN(parsedValue) && parsedValue > 0;
  }, "Product is required"),
  
  Quantity: z.union([z.string(), z.number()]).refine((value) => {
    const parsedValue = typeof value === "string" ? parseFloat(value) : value;
    return !isNaN(parsedValue) && parsedValue > 0;
  }, "Quantity must be greater than 0"),
  
  CostPerHectare: z.union([z.string(), z.number()]).refine((value) => {
    const parsedValue = typeof value === "string" ? parseFloat(value) : value;
    return !isNaN(parsedValue) && parsedValue >= 0;
  }, "Cost per Hectare must be 0 or greater"),

  UOM: z.string().min(1, "Unit of Measure is required"),
});

interface ActivityProductsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  products: any[];
  onSubmit: (product: {
    productId: string;
    product: string;
    quantity: number;
    costPerHectare: number;
    uom: string;
  }) => void;
  editingProduct: any | null;
}

const ActivityProductsDialog: React.FC<ActivityProductsDialogProps> = ({
  isOpen,
  onClose,
  products,
  onSubmit,
  editingProduct,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: zodResolver(productSchema),
    mode: "onChange",
    defaultValues: {
      Product: "",
      Quantity: 1,
      CostPerHectare: 0,
      UOM: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen && editingProduct) {
      setValue("Product", editingProduct.ProductId);
      setValue("Quantity", editingProduct.Quantity);
      setValue("CostPerHectare", editingProduct.CostPerHectare);
      setValue("UOM", editingProduct.UOM);
    } else if (isOpen) {
      reset();
    }
  }, [isOpen, editingProduct, setValue, reset]);

  // const [selectedActiveIngredient, setSelectedActiveIngredient] = useState<string | null>(null);

  const productOptions: any[] = [];
  const seenProductIds = new Set<number>();
  //const activeIngredients = new Set<string>();

  products?.forEach((product: any) => {
    if (!seenProductIds.has(product.IDs.ProductId)) {
      seenProductIds.add(product.IDs.ProductId);
      productOptions.push({
        value: product.IDs.ProductId,
        name: product.ProductName,
        label: `${product.ProductName}`,
        id: product.IDs.ProductId,
        defaultUOM: product.DefaultUOM || "g/Ha",
        //activeIngredient: product.ProductActiveIngredient,
      });
      //activeIngredients.add(product.ProductActiveIngredient);
    }
  });

  productOptions.sort((a, b) => a.name.localeCompare(b.name));


  //const activeIngredientOptions = Array.from(activeIngredients);

  // const filteredProductOptions = productOptions.filter((option) =>
  //   selectedActiveIngredient ? option.activeIngredient === selectedActiveIngredient : true
  // );

  const uomOptions = [
    { value: "g/Ha", label: "Grams per Hectare (g/Ha)" },
    { value: "ml/Ha", label: "Milliliters per Hectare (ml/Ha)" },
  ];

  const handleProductSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const selectedProduct = productOptions.find(
        (product) => product.id === data.Product
      );
      onSubmit({
        productId: selectedProduct?.id || "",
        product: selectedProduct?.name || "",
        quantity: Number(data.Quantity),
        costPerHectare: Number(data.CostPerHectare),
        uom: data.UOM,
      });
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);
  
  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);
  
//   return (
//     <DynamicFormDialog
//       isOpen={isOpen}
//       onClose={handleClose}
//       onSubmit={handleSubmit(handleProductSubmit)}
//       title={"Add Product"}
//       formContent={
//         <form onSubmit={handleSubmit(handleProductSubmit)}>
//           <DialogContent dividers sx={{ pt: 1, pb: 1 }}>
//             <Grid container spacing={2} sx={{ padding: 2 }}>
//               {/* <Grid item xs={12}>
//                 <Autocomplete
//                   options={activeIngredientOptions}
//                   renderInput={(params) => (
//                     <TextField {...params} label="Filter by Active Ingredient" />
//                   )}
//                   value={selectedActiveIngredient}
//                   onChange={(_, newValue) => setSelectedActiveIngredient(newValue)}
//                   fullWidth
//                 />
//               </Grid> */}
//               <Grid item xs={12}>
//                 <Controller
//                   name="Product"
//                   control={control}
//                   render={({ field }) => (
//                     <TextBox
//                       {...field}
//                       label="Product"
//                       select
//                       fullWidth
//                       error={!!errors.Product}
//                       helperText={errors.Product?.message}
//                     >
//                       {filteredProductOptions.map((option) => (
//                         <MenuItem key={option.id} value={option.value}>
//                           {option.label}
//                         </MenuItem>
//                       ))}
//                     </TextBox>
//                   )}
//                 />
//               </Grid>

//               <Grid item xs={6}>
//                 <Controller
//                   name="Quantity"
//                   control={control}
//                   render={({ field }) => (
//                     <TextBox
//                       {...field}
//                       label="Quantity"
//                       type="wholeNumber"
//                       fullWidth
//                       error={!!errors.Quantity}
//                       helperText={errors.Quantity?.message}
//                     />
//                   )}
//                 />
//               </Grid>

//               <Grid item xs={6}>
//                 <Controller
//                   name="CostPerQuantity"
//                   control={control}
//                   render={({ field }) => (
//                     <TextBox
//                       {...field}
//                       label="Cost"
//                       value={field.value}
//                       type="currency"
//                       fullWidth
//                       error={!!errors.CostPerQuantity}
//                       helperText={errors.CostPerQuantity?.message}
//                     />
//                   )}
//                 />
//               </Grid>
//             </Grid>
//           </DialogContent>

//           <DialogActions>
//             <Button
//               variant="contained"
//               color="primary"
//               type="submit"
//               startIcon={<SaveIcon />}
//               disabled={isLoading}
//             >
//               Save
//               {isLoading && (
//                 <CircularProgress color="inherit" size={"15px"} sx={{ ml: 2 }} />
//               )}
//             </Button>
//           </DialogActions>
//         </form>
//       }
//     />
//   );
// };

return (
  <DynamicFormDialog
    isOpen={isOpen}
    onClose={handleClose}
    onSubmit={handleSubmit(handleProductSubmit)}
    title={editingProduct ? "Edit Product" : "Add Product"}
    formContent={
      <form onSubmit={handleSubmit(handleProductSubmit)}>
        <DialogContent dividers sx={{ pt: 1, pb: 1 }}>
          <Grid container spacing={2} sx={{ padding: 2 }}>
            <Grid item xs={12}>
              <Controller
                name="Product"
                control={control}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    label="Product"
                    select
                    fullWidth
                    error={!!errors.Product}
                    helperText={errors.Product?.message}
                    onChange={(e) => {
                      field.onChange(e);
                      const selectedProduct = productOptions.find(
                        (product) => product.id === e.target.value
                      );
                      setValue("UOM", selectedProduct?.defaultUOM || "g/Ha");
                    }}
                  >
                    {productOptions.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextBox>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="Quantity"
                control={control}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    label="Quantity"
                    type="decimal"
                    fullWidth
                    error={!!errors.Quantity}
                    helperText={errors.Quantity?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="UOM"
                control={control}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    label="Unit of Measure"
                    select
                    fullWidth
                    error={!!errors.UOM}
                    helperText={errors.UOM?.message}
                  >
                    {uomOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextBox>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="CostPerHectare"
                control={control}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    label="Cost per Hectare"
                    value={field.value}
                    type="currency"
                    fullWidth
                    error={!!errors.CostPerHectare}
                    helperText={errors.CostPerHectare?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            startIcon={<SaveIcon />}
            disabled={isLoading}
          >
            Save
            {isLoading && (
              <CircularProgress color="inherit" size={"15px"} sx={{ ml: 2 }} />
            )}
          </Button>
        </DialogActions>
      </form>
    }
  />
);
};

export default ActivityProductsDialog;